<template>
  <div class="flex flex-row items-center gap-x-2">
    Page {{ currentPage }} of {{ pageCount }}
    <n-button type="secondary" :disabled="isFirstPage" @click="prev">Previous</n-button>
    <n-button type="secondary" :disabled="isLastPage" @click="next">Next</n-button>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import usePagination from '@/lib/use_pagination'
import modelWrapper from '@/lib/modelWrapper'

const props = defineProps({
  total: {
    type: Number,
    required: true
  },
  pageSize: {
    type: Number,
    default: 20
  },
  page: {
    type: Number,
    default: 1
  }
})
const emit = defineEmits(['update:page'])
const currentPage = modelWrapper<number>(props, emit, 'page')

const { total, pageSize } = toRefs(props)
const {
  pageCount,
  prev,
  next,
  isFirstPage,
  isLastPage
} = usePagination(total, currentPage, pageSize)
</script>
