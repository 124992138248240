import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "max-h-64 overflow-y-auto" }
const _hoisted_6 = {
  key: 0,
  class: "text-brownish-gray-500 px-2 py-1"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ListboxButton, {
        class: _normalizeClass(["w-full focus:outline-none active:outline-none", _ctx.$attrs.disabled ? 'cursor-default' : 'cursor-pointer']),
        onFocus: _ctx.onFocused,
        onBlur: _ctx.onBlurred
      }, {
        default: _withCtx(({ open }) => [
          _createElementVNode("div", {
            class: _normalizeClass([[{ 'ring': _ctx.focused || open }, _ctx.$attrs.disabled ? 'border-brownish-gray-200 bg-brownish-gray-100': 'border-brownish-gray-300 bg-white'], "flex flex-row items-center justify-between select-none border rounded"])
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_n_icon, {
                    key: 0,
                    name: _ctx.icon,
                    scale: "1.5",
                    class: "ml-2 my-2 text-brownish-gray-400"
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                (_ctx.label)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["absolute ml-2 whitespace-nowrap flex flex-row items-center top-0 left-0 pointer-events-none transform origin-top-left duration-300 ease-in-out text-brownish-gray-500", _ctx.focused || open || _ctx.model ? 'scale-75 mt-0.5': 'mt-2'])
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                      (_ctx.required)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["ml-2 whitespace-nowrap", _ctx.label ? 'pt-4': 'py-2']),
                  style: _normalizeStyle(_ctx.label ? 'padding-bottom: .1rem;': '')
                }, [
                  _renderSlot(_ctx.$slots, "button", { value: _ctx.model }, () => [
                    (_ctx.model)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getModelDisplay(_ctx.model)), 1))
                      : _createCommentVNode("", true),
                    _createTextVNode("   ")
                  ])
                ], 6)
              ])
            ]),
            _createVNode(_component_n_icon, {
              flip: open ? 'vertical' : '',
              name: "chevron-down",
              class: "m-2"
            }, null, 8, ["flip"])
          ], 2)
        ]),
        _: 3
      }, 8, ["onFocus", "onBlur", "class"]),
      _createVNode(_Transition, {
        "enter-active-class": "transition duration-100 ease-out",
        "enter-from-class": "transform scale-95 opacity-0",
        "enter-to-class": "transform scale-100 opacity-100",
        "leave-active-class": "transition duration-75 ease-out",
        "leave-from-class": "transform scale-100 opacity-100",
        "leave-to-class": "transform scale-95 opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ListboxOptions, { class: "absolute w-full focus:outline-none active:outline-none bg-white border shadow-md rounded mt-1 z-30 overflow-hidden" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.items.length === 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, "No Options Available"))
                  : (!_ctx.required)
                    ? (_openBlock(), _createBlock(_component_ListboxOption, {
                        key: 1,
                        class: "focus:outline-none active:outline-none cursor-pointer select-none",
                        value: null
                      }, {
                        default: _withCtx(({ active }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([{ 'bg-brownish-gray-100': active }, "p-2 text-gray-500"])
                          }, " ", 2)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    key: _ctx.getId(item),
                    value: _ctx.getValue(item),
                    class: "focus:outline-none active:outline-none cursor-pointer select-none"
                  }, {
                    default: _withCtx(({ selected, active }) => [
                      _renderSlot(_ctx.$slots, "default", {
                        item: item,
                        selected: selected,
                        active: active,
                        index: i
                      }, () => [
                        _createElementVNode("div", {
                          class: _normalizeClass([{ 'text-blue-700 font-bold': selected, 'bg-brownish-gray-100': active || selected }, "p-2 whitespace-nowrap"])
                        }, [
                          (_ctx.getDisplay(item))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.getDisplay(item)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_8, " "))
                        ], 2)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue"]))
}