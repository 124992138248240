import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import Organization from '@/models/organization'
import OrganizationService from '@/services/organization_service'

@Module
class OrgModule extends VuexModule {
  private CURRENT_ORG_KEY = 'nullstone_org'

  currentOrg = localStorage.getItem(this.CURRENT_ORG_KEY) as string
  isLoaded = false
  organizations = [] as Organization[]

  get find() {
    return (orgName: string): Organization | undefined => {
      return this.organizations.find(o => o.name === orgName)
    }
  }

  @Action
  async ensureOrgsLoaded() {
    if (!this.isLoaded) {
      const userOrgs = await OrganizationService.query()
      this.setOrgs(userOrgs)
    }
  }

  @Action
  async createOrg({ org, personal = false }: { org: Organization, personal: boolean }) {
    const newOrg = await OrganizationService.create(org, personal)
    this.addOrg(newOrg)
  }

  @Mutation
  setCurrentOrg(orgName: string) {
    localStorage.setItem(this.CURRENT_ORG_KEY, orgName)
    this.currentOrg = orgName
  }

  @Mutation
  setOrgs(orgs: Organization[]) {
    this.organizations = orgs
    this.isLoaded = true
  }

  @Mutation
  addOrg(newOrg: Organization) {
    this.organizations.push(newOrg)
  }
}

export default new OrgModule({ store, name: 'org' })
