import { VuexModule, Module as VCModule, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import Stack from '@/models/stack'
import { Dictionary } from '@/models/dictionary'
import StackService from '@/services/stack_service'

@VCModule
class StacksModule extends VuexModule {
  currentStacks = JSON.parse((localStorage.getItem('nullstone_stack') || '{}'))
  isLoaded = {} as Dictionary<boolean>
  stacks = {} as Dictionary<Stack[]>
  private CURRENT_STACK_KEY = 'nullstone_stack'

  get currentStack() {
    return (orgName: string): number | undefined => {
      const stackIdString = this.currentStacks[orgName]
      return stackIdString ? parseInt(stackIdString) : undefined
    }
  }

  get query() {
    return (orgName: string, includeGlobal = true): Stack[] => {
      const result = (this.stacks[orgName] || []).sort((a, b) => {
        if (a.name === 'global') { return 1 }
        if (b.name === 'global') { return -1 }
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
      return !includeGlobal
        ? result.filter(stack => stack.name.toLowerCase() !== 'global')
        : result
    }
  }

  get find() {
    return (orgName: string, stackId: number): Stack | undefined => {
      return (this.stacks[orgName] || []).find(s => {
        return s.id === stackId
      })
    }
  }

  get findGlobal() {
    return (orgName: string): Stack | undefined => {
      return (this.stacks[orgName] || []).find(s => {
        return s.name.toLowerCase() === 'global'
      })
    }
  }

  get globalStack() {
    return (orgName: string): Stack | undefined => {
      return (this.stacks[orgName] || []).find(s => {
        return s.name === 'global'
      })
    }
  }

  @Action
  async ensureStacksLoaded(orgName: string) {
    if (!this.isLoaded[orgName] || this.stacks[orgName].length === 0) {
      const stacks = await StackService.query(orgName)
      this.setStacks({ orgName, stacks })
    }
  }

  @Action
  async createStack(stack: Stack): Promise<Stack> {
    const newStack = await StackService.create(stack.orgName, stack)
    this.addStack(newStack)
    return newStack
  }

  @Action
  async updateStack(stack: Stack) {
    const updatedStack = await StackService.update(stack.orgName, stack.id, stack)
    this.setStack(updatedStack)
  }

  @Action
  async deleteStack(stack: Stack) {
    await StackService.delete(stack.orgName, stack.id)
    this.removeStack(stack)
  }

  @Mutation
  setCurrentStack({ orgName, stackId } : { orgName: string, stackId: number }) {
    const newValue = structuredClone(this.currentStacks)
    newValue[orgName] = stackId.toString()
    localStorage.setItem(this.CURRENT_STACK_KEY, JSON.stringify(newValue))
    this.currentStacks = newValue
  }

  @Mutation
  addStack(stack: Stack) {
    if (this.stacks[stack.orgName]) {
      this.stacks[stack.orgName].push(stack)
    } else {
      this.stacks[stack.orgName] = [stack]
    }
  }

  @Mutation
  setStack(stack: Stack) {
    const existing = (this.stacks[stack.orgName] || []).find(s => s.id === stack.id)
    if (existing) {
      Object.assign(existing, stack)
    }
  }

  @Mutation
  setStacks({ orgName, stacks } : { orgName: string, stacks: Stack[] }) {
    this.stacks[orgName] = stacks
    this.isLoaded[orgName] = true
  }

  @Mutation
  removeStack(stack: Stack) {
    const index = (this.stacks[stack.orgName] || [])
      .findIndex(s => s.id === stack.id)
    if (index >= 0) {
      this.stacks[stack.orgName].splice(index, 1)
    }
  }
}

export default new StacksModule({ store, name: 'stacks' })
