import { AxiosError } from 'axios'
import ApiError, { ApiErrorData } from '@/services/api_error'
import { Dictionary } from '@/models/dictionary'

export type ValidationErrors = Record<string, string[]>

function formatValidationErrors(errors: Dictionary<string[]>, fieldNames: Dictionary<string> = {}): string[] {
  const result: string[] = []
  const fields = Object.keys(errors)
  fields.forEach(field => {
    let fieldName = fieldNames[field]
    if (!fieldName) {
      if (field.indexOf('.') !== -1) {
        fieldName = field
      } else {
        let fieldParts = field.split('_')
        fieldParts = fieldParts.map(part => `${part[0].toUpperCase()}${part.substr(1)}`)
        fieldName = fieldParts.join(' ')
      }
    }
    errors[field].forEach((error: string) => {
      if (fieldName === 'Base') {
        result.push(error)
      } else {
        result.push(`${fieldName} ${error}`)
      }
    })
  })
  return result
}

export default class ApiValidationError extends ApiError {
  public validationErrors: ValidationErrors
  public nameMap?: Dictionary<string>

  constructor(error: AxiosError) {
    super(error)
    this.name = 'ApiValidationError'
    this.message = 'Validation failed'
    const data = error.response?.data as ApiErrorData
    this.validationErrors = data.validation_errors || {}
    this.errorMessages = formatValidationErrors(this.validationErrors, this.nameMap)
  }
}
