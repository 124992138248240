import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["disabled", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative border rounded flex flex-row bg-white border-brownish-gray-300", _ctx.$attrs.disabled ? 'opacity-50': ''])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "absolute top-0 left-0 pointer-events-none transform origin-top-left duration-300 ease-in-out text-brownish-gray-500 translate-x-2",
          style: _normalizeStyle(_ctx.labelUp ? '--tw-scale-x: .75; --tw-scale-y: .75; --tw-translate-y: .2rem; --tw-translate-x: .55rem;': '--tw-translate-y: .55rem;')
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true)
        ], 4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocused && _ctx.onFocused(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlurred && _ctx.onBlurred(...args))),
      disabled: _ctx.$attrs.disabled,
      rows: _ctx.lines,
      class: _normalizeClass(["w-full rounded appearance-none focus:outline-none focus:ring px-2", _ctx.label ? 'pt-4': 'py-2']),
      style: _normalizeStyle(_ctx.label ? 'padding-bottom: .15rem;': '')
    }, null, 46, _hoisted_2), [
      [
        _vModelText,
        _ctx.value,
        void 0,
        { trim: true }
      ]
    ])
  ], 2))
}