import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Icon from '@/icons'
import registerGlobalComponents from '@/components/base'
import { getAppConfig } from '@/models/app_config'
import '@/assets/styles/index.css'
import analytics from '@/lib/analytics'
import errorLogger from '@/lib/error_logger'
import errorHandler from '@/lib/error_handler'
import { env } from '@/lib/use_current_user'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

getAppConfig().then(ac => {
  env.value = ac.nullstoneEnv
  errorLogger.init(ac)
  analytics.init(ac.posthogApiKey)
  const app = createApp(App)
  app.provide('appConfig', ac)
  app.provide('betaOrgs', ac.betaOrgs || [])
  registerGlobalComponents(app)
  app.config.errorHandler = errorHandler
  app
    .use(router)
    .use(store)
    .use(autoAnimatePlugin)
    .component('n-icon', Icon)
    .mount('#app')
})
