import { VuexModule, Module as VCModule, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import Module from '@/models/module'
import ModuleService from '@/services/module_service'
import { parseModuleSource } from '@/models/module_source'
import ModuleCategory from '@/models/module_category'
import ModuleCategoriesService from '@/services/module_categories_service'
import { Dictionary } from '@/models/dictionary'

const findModule = (orgName: string, moduleName: string | undefined, modules: Dictionary<Module[]>): Module | undefined => {
  if (!moduleName) { return undefined }
  const moduleSource = parseModuleSource(moduleName)
  return (modules[orgName] || []).find(m => {
    return m.orgName === moduleSource.orgName && m.name === moduleSource.moduleName
  })
}

@VCModule
class ModulesModule extends VuexModule {
  isLoaded = {} as Dictionary<boolean>
  modules = {} as Dictionary<Module[]>
  categories = [] as ModuleCategory[]

  get find() {
    return (orgName: string, moduleName: string | undefined): Module | undefined => {
      return findModule(orgName, moduleName, this.modules)
    }
  }

  get forOrg() {
    return (orgName: string, includeDraft = false): Module[] => {
      return (this.modules[orgName] || [])
        .filter(m => includeDraft || m.status === 'published')
    }
  }

  @Action
  async ensureModulesLoaded(orgName: string) {
    if (!this.isLoaded[orgName]) {
      const modules = await ModuleService.query(orgName)
      this.setModules({ orgName, modules })
    } else {
      ModuleService.query(orgName).then(modules => {
        this.setModules({ orgName, modules })
      })
    }
  }

  @Action
  async loadCategories() {
    const results = await ModuleCategoriesService.query()
    this.setCategories(results)
  }

  @Mutation
  @Mutation
  setModules({ orgName, modules }: { orgName: string, modules: Module[] }) {
    this.modules[orgName] = modules
    this.isLoaded[orgName] = true
  }

  @Mutation
  setCategories(categories: ModuleCategory[]) {
    this.categories = categories
  }

  @Mutation
  setModuleStatus({ orgName, moduleName, status }: { orgName: string, moduleName: string, status: string }) {
    const module = findModule(orgName, moduleName, this.modules)
    if (!module) throw new Error(`Unable to set the status of module (${moduleName}): not found`)
    module.status = status
  }
}

export default new ModulesModule({ store, name: 'modules' })
