<template>
  <div class="absolute inset-0 flex flex-row">
    <n-the-sidebar class="shrink-0 flex flex-col"></n-the-sidebar>
    <div id="main" class="flex-1 flex flex-col">
      <div id="header" class="h-14 z-20 bg-white border-b border-brownish-gray-300 shadow-md">
        <div class="mx-10">
          <div class="mx-auto max-w-6xl py-2">
            <!-- the -ml-2 and mr-2 below make it so that this lines up with the edges of content -->
            <!-- because we always have the scrollbar area on the right -->
            <div class="-ml-2 mr-2 flex flex-row items-center justify-between">
              <n-the-breadcrumb></n-the-breadcrumb>
              <div class="flex flex-row items-center gap-x-4">
                <router-link :to="{ name: 'registry' }" target="_blank"
                             class="rounded hover:bg-brownish-gray-100 text-brownish-gray-600 px-3 py-2 focus:outline-none focus:ring flex flex-row items-center gap-x-1">
                  <n-icon name="fas/cubes" scale="1.5"></n-icon>
                  Registry
                </router-link>
                <a href="https://docs.nullstone.io" target="_blank"
                   class="rounded hover:bg-brownish-gray-100 text-brownish-gray-600 px-3 py-2 focus:outline-none focus:ring flex flex-row items-center gap-x-1">
                  <n-icon name="docs" scale="1.5"></n-icon>
                  Docs
                </a>
                <n-the-user-menu class="ml-2"></n-the-user-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <n-toast-target class="mr-2"></n-toast-target>
      <n-slideover :module="slideoverModule" :content="slideoverContent" v-model:open="slideoverOpen"></n-slideover>
      <div id="container" class="flex-1 relative overflow-y-scroll">
        <div id="content" class="p-8">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import NTheUserMenu from '@/components/TheUserMenu.vue'
import NToastTarget from '@/components/base/ToastTarget.vue'
import NSlideover from '@/components/base/Slideover.vue'
import slideoverStore from '@/store/slideover'
import NTheSidebar from '@/components/TheSidebar.vue'
import NTheBreadcrumb from '@/components/breadcrumb/TheBreadcrumb.vue'

const slideoverOpen = computed({
  get: () => slideoverStore.show,
  set: (value) => slideoverStore.setShow(value)
})
const slideoverModule = computed(() => slideoverStore.module)
const slideoverContent = computed(() => slideoverStore.content)
</script>
