import User from '@/models/user'

declare global {
  interface Window {
    Atlas: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

class Atlas {
  user: User | undefined

  identify(user: User) {
    // we only set this up in prod, if not defined - just exit
    if (!window.Atlas) { return }
    // if we already have a user and it is the same one - just exit
    if (this.user && this.user.id === user.id) { return }

    this.user = user
    window.Atlas.identify({
      userId: user.id,
      name: user.name,
      email: user.email
    })
  }
}

const atlas = new Atlas()

export default atlas
