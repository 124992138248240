import { AxiosError } from 'axios'
import ApiError from '@/services/api_error'

export default class ApiNotAuthenticatedError extends ApiError {
  constructor(error: AxiosError) {
    super(error)
    this.name = 'ApiNotAuthenticatedError'
    this.message = 'Not Authenticated'
    this.errorMessages = ['User is not authenticated.']
  }
}
