import Badge from './Badge.vue'
import Button from './Button.vue'
import ConfirmDialog from '@/components/base/ConfirmDialog.vue'
import Dialog from './Dialog.vue'
import Empty from './Empty.vue'
import Info from './Info.vue'
import Loading from './Loading.vue'
import Pagination from './Pagination.vue'
import Panel from './Panel.vue'
import ProgressBar from './ProgressBar.vue'
import RouterTabItem from './RouterTabItem.vue'
import Select from './Select.vue'
import SkeletonCircle from '@/components/base/SkeletonCircle.vue'
import SkeletonRectangle from './SkeletonRectangle.vue'
import TextArea from './TextArea.vue'
import TextInput from './TextInput.vue'
import Toggle from './Toggle.vue'
import Upload from './Upload.vue'
import UsageGraph from '@/components/base/charts/UsageGraph.vue'
import UsagePercentGraph from '@/components/base/charts/UsagePercentGraph.vue'
import InvocationsGraph from '@/components/base/charts/InvocationsGraph.vue'
import DurationGraph from '@/components/base/charts/DurationGraph.vue'
import GenericLineGraph from '@/components/base/charts/GenericLineGraph.vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalComponents: { [key: string]: any } = {
  'n-badge': Badge,
  'n-button': Button,
  'n-confirm-dialog': ConfirmDialog,
  'n-dialog': Dialog,
  'n-empty': Empty,
  'n-info': Info,
  'n-loading': Loading,
  'n-pagination': Pagination,
  'n-panel': Panel,
  'n-progress-bar': ProgressBar,
  'n-select': Select,
  'n-router-tab': RouterTabItem,
  'n-skeleton-circle': SkeletonCircle,
  'n-skeleton-rectangle': SkeletonRectangle,
  'n-text-area': TextArea,
  'n-text-input': TextInput,
  'n-toggle': Toggle,
  'n-upload': Upload,
  'n-usage-graph': UsageGraph,
  'n-usage-percent-graph': UsagePercentGraph,
  'n-invocations-graph': InvocationsGraph,
  'n-duration-graph': DurationGraph,
  'n-generic-line-graph': GenericLineGraph
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export default function registerGlobalComponents(app: any): void {
  Object.keys(globalComponents).forEach((key) => {
    app.component(key, globalComponents[key])
  })
}
