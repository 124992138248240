import { createApi } from '@/services/api'
import Block from '@/models/block'

export default class BlockService {
  public static async query(orgName: string, stackId: number): Promise<Block[]> {
    const api = await createApi()
    const res = await api.get<Block[]>(`orgs/${orgName}/stacks/${stackId}/blocks`)
    return res.data
  }

  public static async create(orgName: string, stackId: number, block: Block): Promise<Block> {
    const api = await createApi()
    const res = await api.post<Block>(`orgs/${orgName}/stacks/${stackId}/blocks`, block)
    return res.data
  }

  public static async get(orgName: string, stackId: number, blockId: number): Promise<Block> {
    const api = await createApi()
    const res = await api.get<Block>(`orgs/${orgName}/stacks/${stackId}/blocks/${blockId}`)
    return res.data
  }

  public static async update(orgName: string, stackId: number, block: Block): Promise<Block> {
    const api = await createApi()
    const res = await api.put<Block>(`orgs/${orgName}/stacks/${stackId}/blocks/${block.id}`, block)
    return res.data
  }

  public static async delete(orgName: string, stackId: number, blockId: number): Promise<null> {
    const api = await createApi()
    return await api.delete(`orgs/${orgName}/stacks/${stackId}/blocks/${blockId}`)
  }
}
