import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "border-2 border-dashed rounded border-brownish-gray-300 bg-brownish-gray-100/50 p-10 flex flex-col items-center justify-center"
}
const _hoisted_2 = { class: "mt-2 font-semibold" }
const _hoisted_3 = { class: "mt-2 text-center text-brownish-gray-500 max-w-sm" }
const _hoisted_4 = {
  key: 1,
  class: "p-6 border-2 border-dashed rounded border-brownish-gray-300 bg-brownish-gray-100/50"
}
const _hoisted_5 = { class: "flex flex-row items-start gap-x-4" }
const _hoisted_6 = { class: "mr-10 mt-1 font-semibold" }
const _hoisted_7 = { class: "text-brownish-gray-500 max-w-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!

  return (_ctx.size === 'regular')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_n_icon, {
          name: _ctx.iconName,
          scale: "2.5",
          class: "text-brownish-gray-400"
        }, null, 8, ["name"]),
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : (_ctx.size === 'small')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_n_icon, {
              name: _ctx.iconName,
              scale: "2",
              class: "text-brownish-gray-400"
            }, null, 8, ["name"]),
            _createElementVNode("div", null, [
              _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
}