import useCapabilitySubcategories from '@/lib/use_capability_subcategories'
import Module from '@/models/module'
import ModuleVersion from '@/models/module_version'
import { parseModuleContractName } from '@/models/module_contract_name'

const { getIconName: getSubcategoryIconName } = useCapabilitySubcategories()
const getCapIcon = (module: Module): string | null => {
  const connections = ModuleVersion.connections(module.latestVersion)
  if (connections.length === 0) {
    return null
  }
  if (connections.length > 1) {
    throw new Error('Capabilities with multiple connections are not supported')
  }
  const contract = parseModuleContractName(connections[0].contract)
  return contract.category === 'subdomain' || contract.category === 'domain' ? 'custom-subdomain' : contract.platform
}

const getCapabilityIconName = (capModule: Module | undefined): string | null => {
  if (!capModule) { return null }
  return getCapIcon(capModule) || getSubcategoryIconName(capModule.subcategory)
}

export default getCapabilityIconName
