import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["checked", "disabled"]
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row items-center", _ctx.disabled ? 'opacity-50': ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", _ctx.disabled ? '': 'cursor-pointer']),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("input", {
        checked: _ctx.modelValue,
        ref: "checkboxRef",
        type: "checkbox",
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocused && _ctx.onFocused(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlurred && _ctx.onBlurred(...args))),
        disabled: _ctx.disabled,
        class: "absolute opacity-0"
      }, null, 40, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-4 h-2 m-2 shrink-0 rounded-full shadow-inner opacity-25", _ctx.modelValue ? 'bg-blue-600': 'bg-brownish-gray-600'])
        }, null, 2),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        style: {"top":".35rem"},
        class: _normalizeClass(["absolute w-3 h-3 rounded-full border transform transition-transform duration-200 ease-in-out", [
      _ctx.modelValue ? 'border-transparent bg-blue-600 translate-x-4': 'border-brownish-gray-300 bg-white translate-x-1',
      _ctx.focused ? 'ring': 'shadow-none'
      ]])
      }, null, 2)
    ], 2)
  ], 2))
}