import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border border-brownish-gray-300 rounded bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "p-2 border-b rounded-t border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-between"
}
const _hoisted_3 = {
  key: 2,
  class: "border-t rounded-b border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-end space-x-3 p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    (_ctx.status)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['h-2', _ctx.status])
        }, null, 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.hasActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : _createCommentVNode("", true)
  ]))
}