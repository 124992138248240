import { VuexModule, Module as VCModule, Mutation } from 'vuex-class-modules'
import store from '@/store'
import WorkspaceStatus from '@/models/workspace_status'

@VCModule
class WorkspaceStatusesModule extends VuexModule {
  statuses: WorkspaceStatus[] = []

  get query() {
    return () => {
      return this.statuses
    }
  }

  get find() {
    return (blockId: number, envId: number): WorkspaceStatus | undefined => {
      return this.statuses.find(ws => {
        return ws.blockId === blockId && ws.envId === envId
      })
    }
  }

  @Mutation
  setStatuses(statuses : WorkspaceStatus[]) {
    this.statuses = statuses
  }

  @Mutation
  upsertStatus(workspaceStatus: WorkspaceStatus) {
    const existing = this.statuses.find(ws => ws.workspaceUid === workspaceStatus.workspaceUid)
    if (existing) {
      Object.assign(existing, workspaceStatus)
    } else {
      this.statuses.push(workspaceStatus)
    }
  }
}

export default new WorkspaceStatusesModule({ store, name: 'workspace_statuses' })
