import { AppConfig } from '@/models/app_config'
import ApiError from '@/services/api_error'
import Appsignal from '@appsignal/javascript'
import { plugin as pathDecorator } from '@appsignal/plugin-path-decorator'
import { getCurrentUser } from '@/lib/use_current_user'

class ErrorLogger {
  accessToken: string | undefined
  environment: string | undefined
  codeVersion: string | undefined

  init(ac: AppConfig) {
    this.accessToken = ac.appsignalApiKey
    this.environment = ac.nullstoneEnv
    this.codeVersion = ac.codeVersion
  }

  log(error: Error) {
    const currentUser = getCurrentUser()
    let requestId = ''
    if (error instanceof ApiError) {
      requestId = error.requestId
      console.error(error.cause) // eslint-disable-line no-console
      console.log(error.json) // eslint-disable-line no-console
    } else {
      console.error(error) // eslint-disable-line no-console
    }
    if (!this.accessToken) { return }
    const logger = new Appsignal({
      key: this.accessToken,
      revision: this.codeVersion
    })
    logger.use(pathDecorator)
    logger.sendError(error, (span) => {
      span.setTags({
        user_id: currentUser.id,
        user_email: currentUser.email,
        user_name: currentUser.name,
        request_id: requestId
      })
    })
  }
}

const errorLogger = new ErrorLogger()

export default errorLogger
