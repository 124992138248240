import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { getAppConfig } from '@/models/app_config'
import generateUuidV4 from '@/lib/uuid'
import ApiBadRequestError from '@/services/api_bad_request_error'
import ApiValidationError from '@/services/api_validation_error'
import ApiError from '@/services/api_error'
import ApiNotAuthenticatedError from '@/services/api_not_authenticated_error'
import ApiNotAuthorizedError from '@/services/api_not_authorized_error'

/*
  This client authenticates with the Nullstone APIs using session-cookie authentication
  combined with a CSRF token in order to prevent cross-site request forgery.

  This client must have 4 things in order to authenticate with the Nullstone API:
  1) Be used in a browser
  2) Be used within the same domain as the API, a different sub-domain is valid
    (e.g. app.nullstone.io can make calls to the Nullstone API hosted at api.nullstone.io -
    the session cookie is valid for all of *.nullstone.io)
  3) A valid session cookie is set in the browser
  4) A valid CSRF cookie is set in the browser

  Because of requirement #1 and #2 above, the session cookie will automatically be sent along in all requests by the
  browser. This will allow us to authentication for all GET requests.

  In order to authenticate for all PUT, POST, and DELETE requests, we also need to send across the CSRF token in the
  header.

  The 3 settings below (xsrfCookieName, xsrfHeaderName, and withCredentials)
  will tell axios to add the CSRF token to the header of each request.
 */

axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.Accept = 'application/json'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = (config) => !!config.withCredentials

function createBase(baseURL: string, xsrfCookieName: string) {
  const api = axios.create({ baseURL, xsrfCookieName })
  api.interceptors.request.use(
    (requestConfig: InternalAxiosRequestConfig) => {
      requestConfig.headers['X-Request-Id'] = generateUuidV4()
      return requestConfig
    }
  )
  // TODO: don't need to do this here, use `isAxiosError` instead in the error_handler
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response) {
        switch (error.response.status) {
        case 400:
          throw new ApiBadRequestError(error)
        case 401:
          throw new ApiNotAuthenticatedError(error)
        case 403:
          throw new ApiNotAuthorizedError(error)
        case 422:
          throw new ApiValidationError(error)
        }
      }
      throw new ApiError(error)
    }
  )
  return api
}

export async function createApi(): Promise<AxiosInstance> {
  const appConfig = await getAppConfig()
  return createBase(appConfig.apiHost, appConfig.csrfCookieName)
}

export async function createAuth(): Promise<AxiosInstance> {
  const appConfig = await getAppConfig()
  return createBase(appConfig.authHost, appConfig.csrfCookieName)
}
