import cookie from 'js-cookie'

export class AppConfig {
  public nullstoneEnv!: string
  public apiHost!: string
  public authHost!: string
  public appsignalApiKey!: string
  public codeVersion!: string
  public stripeApiKey!: string
  public posthogApiKey!: string
  public betaOrgs!: string[]
  public knockHost!: string

  // retrieveCsrfToken is used to forcefully retrieve csrf token from the server
  // We may have a csrf token in our cookies, but that doesn't mean it's a valid token
  // This is used primarily for OAuth flows where we need to embed `authenticity_token` form field
  public async retrieveCsrfToken(): Promise<string> {
    await fetch(`${this.apiHost}/ping`, { method: 'GET', credentials: 'include' })
    return cookie.get(this.csrfCookieName) || ''
  }

  public get csrfCookieName(): string {
    return `${this.nullstoneEnv}_csrf_token`
  }
}

interface EnvConfig {
  NULLSTONE_ENV: string;
  API_HOST: string;
  AUTH_HOST: string;
  APPSIGNAL_API_KEY: string;
  CODE_VERSION: string;
  STRIPE_API_KEY: string;
  POSTHOG_API_KEY: string;
  BETA_ORGS: string;
  KNOCK_HOST: string;
}

// This technique allows us to memoize the app config
// This ensures we only load the file once since we need it for Vue load and api service creation
let config: AppConfig
export async function getAppConfig(): Promise<AppConfig> {
  if (!config) {
    config = new AppConfig()
    try {
      const res = await fetch('/env.json')
      const data: EnvConfig = await res.json()
      config.nullstoneEnv = data.NULLSTONE_ENV
      config.apiHost = data.API_HOST
      config.authHost = data.AUTH_HOST
      config.appsignalApiKey = data.APPSIGNAL_API_KEY
      config.codeVersion = data.CODE_VERSION
      config.stripeApiKey = data.STRIPE_API_KEY
      config.posthogApiKey = data.POSTHOG_API_KEY
      config.betaOrgs = (data.BETA_ORGS || '').split(',')
      config.knockHost = data.KNOCK_HOST
    } catch {
    }
  }

  return config
}
