import { AxiosError } from 'axios'
import ApiError, { ApiErrorData } from '@/services/api_error'

export interface ApiBadRequestDetails {
  // type provides the class type of the error
  type?: string
  // reason provides a high-level reason for bad request
  reason?: string
  // parameter is used to identify the URL path parameter or query parameter that is invalid
  parameter?: string
  // attribute is used to identify the payload attribute that is invalid
  attribute?: string

  message: string
}

export default class ApiBadRequestError extends ApiError {
  public details: ApiBadRequestDetails

  constructor(error: AxiosError) {
    super(error)
    this.name = 'ApiBadRequestError'
    this.message = 'Bad request'
    const data = error.response?.data as ApiErrorData
    const details = data?.details as ApiBadRequestDetails|string|undefined

    // Load details based on potential for different values: <null>, string, ApiBadRequestDetails
    // We are currently migrating to a consistent format; we need to support "string" until fully migrated
    if (!details || typeof details === 'string') {
      this.details = { message: details || '' } as ApiBadRequestDetails
    } else {
      this.details = details
    }

    this.errorMessages = [this.details.message]
  }
}
