import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-start" }
const _hoisted_2 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["border rounded p-2", `${_ctx.borderColor} ${_ctx.bgColor}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_n_icon, {
            key: 0,
            name: _ctx.icon,
            class: _normalizeClass(["mt-1 mr-2", _ctx.iconColor])
          }, null, 8, ["name", "class"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 2))
}