import Module from '@/models/module'
import getCapabilityIconName from '@/lib/get_capability_icon_name'
import getCategoryIconName from '@/lib/get_category_icon_name'
import { isAutogenSubdomain } from '@/models/subdomain'

export default function getModuleIconName(module: Module): string {
  if (module.category === 'capability') {
    return getCapabilityIconName(module) || ''
  }
  if (module.category === 'subdomain') {
    return isAutogenSubdomain(`${module.orgName}/${module.name}`) ? 'autogen-subdomain' : 'custom-subdomain'
  }
  return module.platform
}

export function getModuleIconName2(module: Module): string {
  switch (module.category) {
  case 'app':
    return getCategoryIconName(module.subcategory)
  case 'datastore':
    return module.platform || 'fas/database'
  case 'domain':
    return 'custom-subdomain'
  case 'subdomain':
    return isAutogenSubdomain(`${module.orgName}/${module.name}`) ? 'autogen-subdomain' : 'custom-subdomain'
  case 'capability':
    return 'lightning'
  default:
    return 'fas/cube'
  }
}
