<template>
  <router-link :to="menuItem.path"
               class="px-3 py-2 rounded hover:bg-gray-700 focus:outline-none focus:ring flex flex-row items-center gap-x-2"
      :class="menuItem.highlighted ? 'text-white bg-gray-700' : 'text-brownish-gray-400'">
    <n-icon :name="menuItem.icon"></n-icon>
    <div class="truncate">{{ menuItem.title }}</div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuItemComponent',
  props: {
    menuItem: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { }
  }
})
</script>
