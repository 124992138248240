import { onUnmounted, Ref, watch } from 'vue'
import workspaceStore from '@/store/workspaces'
import { getStackWorkspacesUrl, WebsocketMessage } from '@/services/ws'
import { useWebSocket } from '@vueuse/core'

const useLiveStackWorkspaces = (orgName: Ref<string>, stackId: Ref<number>): void => {
  let close: WebSocket['close']
  let connectedUrl: string

  const start = async () => {
    const wsUrl = await getStackWorkspacesUrl(orgName.value, stackId.value)
    if (wsUrl === connectedUrl) { return }

    if (close) close()
    if (!orgName.value || !stackId.value) { return }
    connectedUrl = wsUrl;
    ({ close } = useWebSocket(connectedUrl, {
      heartbeat: {
        message: 'ping',
        interval: 10000
      },
      onMessage: receiveWorkspaces,
      autoReconnect: true
    }))
  }

  watch(orgName, async () => { await start() })
  watch(stackId, async () => { await start() })

  const receiveWorkspaces = (ws: WebSocket, message: MessageEvent) => {
    const parsed = JSON.parse(message.data) as WebsocketMessage
    if (parsed.context === 'hydrate') {
      const workspaces = JSON.parse(parsed.content)
      workspaceStore.setWorkspaces(workspaces)
    } else {
      const workspaceChanges = JSON.parse(parsed.content)
      workspaceStore.upsertWorkspace(workspaceChanges)
    }
  }

  onUnmounted(() => close())
}

export default useLiveStackWorkspaces
