import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_toast = _resolveComponent("n-toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      tag: "div",
      class: "absolute overflow-hidden p-6 right-0 top-0 w-full max-w-lg flex flex-col space-y-2 z-50",
      "enter-from-class": "translate-x-full opacity-0",
      "enter-active-class": "duration-500",
      "leave-active-class": "duration-500",
      "leave-to-class": "translate-x-full opacity-0"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
          return (_openBlock(), _createBlock(_component_n_toast, {
            key: notification.key,
            class: "toast-item",
            notification: notification,
            onRemoveNotification: _ctx.removeNotification
          }, null, 8, ["notification", "onRemoveNotification"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}