<template>
  <!--
    TODO: this uses the HeadlessUI Menu directly
    this should be refactored to use a Dropdown that has a lot of this defaulted
  -->
  <h-menu as="div" class="relative -mb-1" v-slot="{ open }">
    <h-menu-button class="focus:outline-none">
      <button class="mb-1 rounded-full focus:outline-none focus:ring" :class="open ? 'ring' : ''">
        <n-user-picture :user="currentUser" size="medium"></n-user-picture>
      </button>
    </h-menu-button>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <h-menu-items class="absolute z-50 mt-1 bg-white border rounded border-brownish-gray-300 focus:outline-none shadow-md right-0">
        <div class="min-w-64">
          <div class="flex flex-col items-center m-4">
            <n-user-picture :user="currentUser" size="large"></n-user-picture>
            <div class="mt-2">{{ currentUser.name }}</div>
            <div>{{ currentUser.email }}</div>
          </div>
          <h-menu-item v-if="!onlyLogout" class="focus:outline-none" v-slot="{ active }" @click="goToEdit">
            <button class="w-full text-left border-t border-brownish-gray-300 py-2 px-4" :class="active ? 'bg-brownish-gray-100' : ''">
              <n-icon name="fas/user-edit" class="mr-1"></n-icon>
              Edit Profile
            </button>
          </h-menu-item>
          <h-menu-item class="focus:outline-none" v-slot="{ active }" @click="logout">
            <button class="w-full text-left border-t border-brownish-gray-300 py-2 px-4" :class="active ? 'bg-brownish-gray-100' : ''">
              <n-icon name="fas/sign-out-alt" class="mr-1"></n-icon>
              Logout
            </button>
          </h-menu-item>
        </div>
      </h-menu-items>
    </transition>
  </h-menu>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import NUserPicture from '@/components/UserPicture.vue'
import { Menu as HMenu, MenuButton as HMenuButton, MenuItems as HMenuItems, MenuItem as HMenuItem } from '@headlessui/vue'
import { getCurrentUser } from '@/lib/use_current_user'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  onlyLogout: {
    type: Boolean,
    default: false
  }
})

const router = useRouter()
const currentUser = getCurrentUser()

const goToEdit = () => {
  router.push({ name: 'show_profile' })
}
const logout = () => {
  router.push({ name: 'logout' })
}
</script>
