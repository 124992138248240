<template>
  <div class="inline-flex border items-center justify-center px-2 py-0.5 rounded-full text-xs font-medium whitespace-nowrap"
    :class="`${borderColor} ${bgColor} ${textColor}`">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export enum BadgeType {
  DEFAULT = 'default',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  UNKNOWN = 'unknown',
  INFO = 'info'
}

export default defineComponent({
  name: 'BadgeComponent',
  props: {
    type: {
      type: String,
      default: BadgeType.DEFAULT
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const borderColor = computed(() => {
      if (props.darkMode) {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'border-red-400/50'
        case BadgeType.WARNING:
          return 'border-yellow-500/25'
        case BadgeType.SUCCESS:
          return 'border-green-400/25'
        case BadgeType.INFO:
          return 'border-blue-500/25'
        case BadgeType.UNKNOWN:
          return 'border-teal-400/25'
        default:
          return 'border-gray-600/50'
        }
      } else {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'border-red-200'
        case BadgeType.WARNING:
          return 'border-yellow-400'
        case BadgeType.SUCCESS:
          return 'border-green-200'
        case BadgeType.INFO:
          return 'border-blue-200'
        case BadgeType.UNKNOWN:
          return 'border-teal-200'
        default:
          return 'border-gray-300'
        }
      }
    })

    const bgColor = computed(() => {
      if (props.darkMode) {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'bg-red-400/20'
        case BadgeType.WARNING:
          return 'bg-yellow-300/25'
        case BadgeType.SUCCESS:
          return 'bg-green-300/20'
        case BadgeType.INFO:
          return 'bg-blue-300/20'
        case BadgeType.UNKNOWN:
          return 'bg-teal-300/20'
        default:
          return 'bg-gray-400/20'
        }
      } else {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'bg-red-100'
        case BadgeType.WARNING:
          return 'bg-yellow-100'
        case BadgeType.SUCCESS:
          return 'bg-green-100'
        case BadgeType.INFO:
          return 'bg-blue-100'
        case BadgeType.UNKNOWN:
          return 'bg-teal-100'
        default:
          return 'bg-gray-100'
        }
      }
    })

    const textColor = computed(() => {
      if (props.darkMode) {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'text-red-400'
        case BadgeType.WARNING:
          return 'text-yellow-400'
        case BadgeType.SUCCESS:
          return 'text-green-400'
        case BadgeType.INFO:
          return 'text-blue-300'
        case BadgeType.UNKNOWN:
          return 'text-teal-400'
        default:
          return 'text-gray-400/75'
        }
      } else {
        switch (props.type) {
        case BadgeType.ERROR:
          return 'text-red-700'
        case BadgeType.WARNING:
          return 'text-yellow-600'
        case BadgeType.SUCCESS:
          return 'text-green-700'
        case BadgeType.INFO:
          return 'text-blue-700'
        case BadgeType.UNKNOWN:
          return 'text-teal-500'
        default:
          return 'text-gray-600'
        }
      }
    })

    return { borderColor, bgColor, textColor }
  }
})
</script>
