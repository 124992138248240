import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.menuItem.path,
    class: _normalizeClass(["px-3 py-2 rounded hover:bg-gray-700 focus:outline-none focus:ring flex flex-row items-center gap-x-2", _ctx.menuItem.highlighted ? 'text-white bg-gray-700' : 'text-brownish-gray-400'])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_icon, {
        name: _ctx.menuItem.icon
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.menuItem.title), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}