<template>
  <Listbox v-model="model" as="div" class="relative">
    <ListboxButton v-slot="{ open }" class="w-full focus:outline-none active:outline-none" @focus="onFocused" @blur="onBlurred"
      :class="$attrs.disabled ? 'cursor-default' : 'cursor-pointer'">
      <div :class="[{ 'ring': focused || open }, $attrs.disabled ? 'border-brownish-gray-200 bg-brownish-gray-100': 'border-brownish-gray-300 bg-white']"
           class="flex flex-row items-center justify-between select-none border rounded">
        <div class="flex flex-row items-center">
          <n-icon v-if="icon" :name="icon" scale="1.5" class="ml-2 my-2 text-brownish-gray-400"></n-icon>
          <div class="relative">
            <div v-if="label" class="absolute ml-2 whitespace-nowrap flex flex-row items-center top-0 left-0 pointer-events-none transform origin-top-left duration-300 ease-in-out text-brownish-gray-500"
                 :class="focused || open || model ? 'scale-75 mt-0.5': 'mt-2'">
              {{ label }}
              <span v-if="required" class="ml-1">*</span>
            </div>
            <div class="ml-2 whitespace-nowrap"
                 :class="label ? 'pt-4': 'py-2'"
                 :style="label ? 'padding-bottom: .1rem;': ''">
              <slot name="button" :value="model">
                <span v-if="model">{{ getModelDisplay(model) }}</span>
                &nbsp;
              </slot>
            </div>
          </div>
        </div>
        <n-icon :flip="open ? 'vertical' : ''" name="chevron-down" class="m-2"></n-icon>
      </div>
    </ListboxButton>
    <transition enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <ListboxOptions class="absolute w-full focus:outline-none active:outline-none bg-white border shadow-md rounded mt-1 z-30 overflow-hidden">
        <div class="max-h-64 overflow-y-auto">
          <p v-if="items.length === 0" class="text-brownish-gray-500 px-2 py-1">No Options Available</p>
          <ListboxOption v-else-if="!required" class="focus:outline-none active:outline-none cursor-pointer select-none" v-slot="{ active }" :value="null">
            <div :class="{ 'bg-brownish-gray-100': active }" class="p-2 text-gray-500">&nbsp;</div>
          </ListboxOption>
          <ListboxOption v-for="(item, i) in items" :key="getId(item)" :value="getValue(item)" v-slot="{ selected, active }"
                         class="focus:outline-none active:outline-none cursor-pointer select-none">
            <slot :item="item" :selected="selected" :active="active" :index="i">
              <div :class="{ 'text-blue-700 font-bold': selected, 'bg-brownish-gray-100': active || selected }" class="p-2 whitespace-nowrap">
                <span v-if="getDisplay(item)">{{ getDisplay(item) }}</span>
                <span v-else>&nbsp;</span>
              </div>
            </slot>
          </ListboxOption>
        </div>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import modelWrapper from '@/lib/modelWrapper'
import useFocus from '@/components/base/use_focus'

export default defineComponent({
  name: 'SelectComponent',
  components: { Listbox, ListboxButton, ListboxOptions, ListboxOption },
  emits: ['update:modelValue', 'change'],
  props: {
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [Object, String, Number]
    },
    modelDisplay: Function,
    itemId: Function,
    itemValue: Function,
    itemDisplay: Function,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    }
  },
  setup(props, { emit }) {
    const model = modelWrapper(props, emit)
    const { focused, onFocused, onBlurred } = useFocus()

    const getModelDisplay = (model: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (props.modelDisplay) {
        return props.modelDisplay(model)
      } else {
        return model
      }
    }
    const getId = (item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (props.itemId) {
        return props.itemId(item)
      } else {
        return item
      }
    }
    const getValue = (item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (props.itemValue) {
        return props.itemValue(item)
      } else {
        return item
      }
    }
    const getDisplay = (item: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (props.itemDisplay) {
        return props.itemDisplay(item)
      } else {
        return item
      }
    }

    watch(model, (newValue) => {
      emit('change', newValue)
    })

    return { model, focused, onFocused, onBlurred, getId, getValue, getDisplay, getModelDisplay }
  }
})
</script>
