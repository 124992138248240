import { computed, ComputedRef, Ref, ref } from 'vue'
import { getSubcategories } from '@/models/modules_filter'
import moduleStore from '@/store/modules'

export interface SubcategoryDetails {
  name: string
  icon: string
  displayName: string,
  singularName: string,
  order: number
}

const useCapabilitySubcategories = (defaultSubcategory = 'all'): {
  subcategory: Ref<string>,
  subcategories: ComputedRef<SubcategoryDetails[]>,
  getIconName: (subcategory: string) => string
} => {
  const getIconName = (subcategory: string) => {
    switch (subcategory) {
    case 'all':
      return 'grid'
    case 'datastores':
      return 'fas/database'
    case 'ingress':
      return 'subdomain'
    case 'secrets':
      return 'fas/key'
    case 'events':
      return 'fas/play-circle'
    case 'sidecars':
      return 'sidecar'
    case 'telemetry':
      return 'broadcast-tower'
    default:
      throw new Error(`Unable to associate a subcategory icon: unexpected subcategory: ${subcategory}`)
    }
  }
  const displayName = (subcategory: string) => {
    switch (subcategory) {
    case 'all':
      return 'All'
    case 'datastores':
      return 'Datastores'
    case 'ingress':
      return 'Ingress'
    case 'secrets':
      return 'Secrets'
    case 'events':
      return 'Events'
    case 'sidecars':
      return 'Sidecars'
    case 'telemetry':
      return 'Logging & Monitoring'
    default:
      throw new Error(`Unable to associate a display name: unexpected subcategory: ${subcategory}`)
    }
  }
  const singularName = (subcategory: string) => {
    switch (subcategory) {
    case 'all':
      return 'all'
    case 'datastores':
      return 'datastore'
    case 'ingress':
      return 'ingress block'
    case 'secrets':
      return 'secret'
    case 'events':
      return 'event'
    case 'sidecars':
      return 'sidecar'
    case 'telemetry':
      return 'telemetry provider'
    default:
      throw new Error(`Unable to associate a display name: unexpected subcategory: ${subcategory}`)
    }
  }
  const order = (subcategory: string) => {
    switch (subcategory) {
    case 'all':
      return 0
    case 'datastores':
      return 1
    case 'ingress':
      return 2
    case 'secrets':
      return 3
    case 'events':
      return 4
    case 'sidecars':
      return 5
    case 'telemetry':
      return 6
    default:
      throw new Error(`Unable to associate a subcategory icon: unexpected subcategory: ${subcategory}`)
    }
  }

  const subcategory = ref(defaultSubcategory)

  const subcategories = computed(() => {
    const all = [{ name: 'all', description: 'All subcategories' }]
    const subs = getSubcategories(moduleStore.categories, 'capability').concat(all)
    return subs.map(sc => {
      return {
        name: sc.name,
        icon: getIconName(sc.name),
        displayName: displayName(sc.name),
        singularName: singularName(sc.name),
        order: order(sc.name)
      }
    }).sort((a, b) => {
      if (a.order > b.order) { return 1 }
      if (a.order < b.order) { return -1 }
      return 0
    })
  })

  return { subcategory, subcategories, getIconName }
}

export default useCapabilitySubcategories
