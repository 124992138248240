/**
 * Generates a UUID string
 * See https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523 for more details on this
 */
export default function generateUuidV4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

// TODO: enhance this to actually check for valid UUIDs
// this will handle our use case for now
export function validUUID(uuid: string): boolean {
  if (uuid === '00000000-0000-0000-0000-000000000000') { return false }
  return true
}
