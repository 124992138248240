export interface ModuleSource {
    orgName: string;
    moduleName: string;
}

export function parseModuleSource(source: string): ModuleSource {
  const parts = source.split('/')
  return {
    orgName: parts[0],
    moduleName: parts[1]
  }
}

export function sourceLogo(source: string): string {
  if (source.startsWith('github.com')) {
    return 'fab/github'
  }
  if (source.startsWith('registry.terraform.io')) {
    return 'terraform'
  }
  return 'nullstone'
}
