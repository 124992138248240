import { ModuleManifest, ModuleManifestConnection } from '@/models/module_manifest'
import { matchContract, parseModuleContractName } from '@/models/module_contract_name'

export default class ModuleVersion {
  uid!: string
  moduleUid!: string
  version!: string
  createdAt!: Date | null
  manifest!: ModuleManifest | null
  readme!: string

  static connections(moduleVersion: ModuleVersion | null | undefined): ({ name: string; } & ModuleManifestConnection)[] {
    if (moduleVersion && moduleVersion.manifest && moduleVersion.manifest.connections) {
      return Object.keys(moduleVersion?.manifest?.connections).map((key) => {
        const value = Object.assign({ name: '' }, moduleVersion?.manifest?.connections[key])
        value.name = key
        return value
      })
    }
    return []
  }
}

const networkContract = parseModuleContractName('network/*/*')
const clusterContract = parseModuleContractName('cluster/*/*')
const clusterNamespaceContract = parseModuleContractName('cluster-namespace/*/*')

export const isNetworkConnection = (c: ModuleManifestConnection): boolean => {
  // TODO: Remove 'type' checks once fully migrated module taxonomy
  return matchContract(networkContract, c.contract) || c.type.startsWith('network/')
}
export const isClusterNamespaceConnection = (c: ModuleManifestConnection): boolean => {
  return matchContract(clusterNamespaceContract, c.contract)
}
export const isClusterConnection = (c: ModuleManifestConnection): boolean => {
  // TODO: Remove 'type' checks once fully migrated module taxonomy
  return matchContract(clusterContract, c.contract) || c.type.startsWith('cluster/')
}

export const requiredConnections = (moduleVersion: ModuleVersion): ModuleManifestConnection[] => {
  return ModuleVersion.connections(moduleVersion).filter(c => !c.optional)
}

export const hasClusterNamespaceConnection = (moduleVersion: ModuleVersion): boolean => {
  return requiredConnections(moduleVersion).some(isClusterNamespaceConnection)
}
export const hasClusterConnection = (moduleVersion: ModuleVersion): boolean => {
  return requiredConnections(moduleVersion).some(isClusterConnection)
}
export const hasNetworkConnection = (moduleVersion: ModuleVersion): boolean => {
  return requiredConnections(moduleVersion).some(isNetworkConnection)
}

export const clusterNamespaceRequired = (moduleVersion: ModuleVersion): boolean => {
  return requiredConnections(moduleVersion).some(isClusterNamespaceConnection)
}
export const clusterRequired = (moduleVersion: ModuleVersion): boolean => {
  return clusterNamespaceRequired(moduleVersion) || requiredConnections(moduleVersion).some(isClusterConnection)
}
export const networkRequired = (moduleVersion: ModuleVersion): boolean => {
  // all clusters must be placed in a network so if we require a cluster than we automatically require a network
  return clusterRequired(moduleVersion) || requiredConnections(moduleVersion).some(isNetworkConnection)
}

export function hasEnvVariables(mv: ModuleVersion | undefined): boolean {
  return !!mv?.manifest?.variables.env_vars || !!mv?.manifest?.variables.service_env_vars
}
