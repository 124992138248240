<template>
  <div style="height: 100vh;" class="bg-white">
    <div id="header" class="bg-white border-b border-brownish-gray-300">
      <div class="mx-auto w-full max-w-7xl flex flex-row items-center justify-between py-2 px-4 md:px-8">
        <router-link :to="{ name: 'registry_modules' }" class="flex items-center justify-center">
          <div class="overflow-hidden w-36 h-9 text-center">
            <n-icon name="nullstone/fulldark" scale="8" class="-ml-4 text-blue-700"></n-icon>
          </div>
          |
          <h1 class="ml-2 font-semibold">Registry</h1>
        </router-link>
        <div class="flex flex-row items-center gap-x-4">
          <n-button v-if="canRegister" @click="goToNew">Register Module</n-button>
          <!-- TODO: users will now have to select the org they want to register a module to
          <n-button v-if="authorizeOrgArchitect(orgName)" @click.prevent="goToNewModule()" class="ml-auto">
            Register Module
          </n-button>
          -->
          <n-the-user-menu v-if="currentUser" :only-logout="true"></n-the-user-menu>
          <n-button type="secondary" v-else @click="goToLogin">
            <n-icon name="fas/sign-in-alt" class="mr-2"></n-icon>
            Login
          </n-button>
        </div>
      </div>
    </div>
    <div id="container">
      <n-toast-target></n-toast-target>
      <div id="content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import NToastTarget from '@/components/base/ToastTarget.vue'
import NTheUserMenu from '@/components/TheUserMenu.vue'
import { getCurrentUser } from '@/lib/use_current_user'
import { authorizeOrgArchitect } from '@/lib/authorization/org_authorization'

const currentUser = computed(() => getCurrentUser().name)
const router = useRouter()
const goToLogin = () => {
  router.push({ name: 'login', query: { redirect_url: window.location.href } })
}

const canRegister = computed(() => {
  const user = getCurrentUser()
  if (!user) return false
  return user.orgNames.some(orgName => authorizeOrgArchitect(orgName))
})
const goToNew = () => {
  router.push({ name: 'new_module' })
}
</script>
