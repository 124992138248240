import {
  AwsProviderConfig,
  blankAwsProviderConfig,
  isAwsProviderConfigValid
} from '@/models/providers/aws_provider_config'
import {
  blankGcpProviderConfig,
  GcpProviderConfig,
  isGcpProviderConfigValid
} from '@/models/providers/gcp_provider_config'
import { ProviderTypes } from '@/models/provider'

export interface ProviderConfig {
  aws?: AwsProviderConfig
  gcp?: GcpProviderConfig
}

export function blankProviderConfig(): ProviderConfig {
  return {
    aws: blankAwsProviderConfig(),
    gcp: blankGcpProviderConfig()
  }
}

export function newProviderConfig(providerName: string, providerType: string): ProviderConfig {
  // TODO: when we add Azure support we will need to add that use case here
  const pc = blankProviderConfig()
  switch (providerType) {
  case ProviderTypes.Aws:
    pc.aws && (pc.aws.providerName = providerName)
    break
  case ProviderTypes.Gcp:
    pc.gcp && (pc.gcp.providerName = providerName)
    break
  }
  return pc
}

export function getProviderConfigName(providerConfig: ProviderConfig): string {
  return providerConfig?.aws?.providerName || providerConfig?.gcp?.providerName || ''
}

export function isProviderConfigValid(pc: ProviderConfig, providerType: ProviderTypes): boolean {
  if (providerType === ProviderTypes.Aws) {
    const awsPc = pc.aws as AwsProviderConfig
    return isAwsProviderConfigValid(awsPc)
  }
  if (providerType === ProviderTypes.Gcp) {
    const gcpPc = pc.gcp as GcpProviderConfig
    return isGcpProviderConfigValid(gcpPc)
  }
  throw new Error(`Unknown provider type: ${providerType}`)
}
