import { Ref, ref } from 'vue'

export default function useFocus(): { focused: Ref<boolean>, onFocused: () => void, onBlurred: () => void } {
  const focused = ref(false)
  const onFocused = () => {
    focused.value = true
  }
  const onBlurred = () => {
    focused.value = false
  }
  return { focused, onFocused, onBlurred }
}
