// GcpServiceAccount matches the json representation of a service account key file in GCP
export interface GcpServiceAccount {
  type: string;
  // eslint-disable-next-line camelcase
  project_id: string;
  // eslint-disable-next-line camelcase
  private_key_id: string;
  // eslint-disable-next-line camelcase
  private_key: string;
  // eslint-disable-next-line camelcase
  client_email: string;
  // eslint-disable-next-line camelcase
  client_id: string;
  // eslint-disable-next-line camelcase
  auth_uri: string;
  // eslint-disable-next-line camelcase
  token_uri: string;
  // eslint-disable-next-line camelcase
  auth_provider_x509_cert_url: string;
  // eslint-disable-next-line camelcase
  client_x509_cert_url: string;
}

export enum GcpAuthTypes {
  serviceAccount = 'serviceAccount'
}

export const gcpAuthTypeNames: Record<string, string> = {
  serviceAccount: 'Service Account'
}

export default class GcpCredentials {
  authType = ''
  serviceAccount?: GcpServiceAccount
}

export function blankGcpServiceAccountCredentials(): GcpCredentials {
  const creds = new GcpCredentials()
  creds.authType = GcpAuthTypes.serviceAccount
  return creds
}

// parseGcpServiceAccountKeyFile parses a raw file that is encoded as JSON
// This will also ignore fields that are not part of the key file syntax
export function parseGcpServiceAccountKeyFile(rawContent: string): GcpServiceAccount {
  let parsed: any // eslint-disable-line @typescript-eslint/no-explicit-any
  try {
    parsed = JSON.parse(rawContent)
  } catch {
  }
  const fields = [
    'type',
    'project_id',
    'private_key_id',
    'private_key',
    'client_email',
    'client_id',
    'auth_uri',
    'token_uri',
    'auth_provider_x509_cert_url',
    'client_x509_cert_url'
  ]
  return fields.reduce((prev: GcpServiceAccount, field: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prev as any)[field] = parsed[field]
    return prev
  }, {} as GcpServiceAccount)
}
