import { createApi } from '@/services/api'
import Stack from '@/models/stack'

export default class StackService {
  public static async query(orgName: string): Promise<Stack[]> {
    const api = await createApi()
    const res = await api.get<Stack[]>(`orgs/${orgName}/stacks`)
    return res.data
  }

  public static async create(orgName: string, stack: Stack): Promise<Stack> {
    const api = await createApi()
    const res = await api.post<Stack>(`orgs/${orgName}/stacks`, stack)
    return res.data
  }

  public static async delete(orgName: string, stackId: number): Promise<null> {
    const api = await createApi()
    return await api.delete(`orgs/${orgName}/stacks/${stackId}`)
  }

  public static async update(orgName: string, stackId: number, stack: Stack): Promise<Stack> {
    const api = await createApi()
    const res = await api.put<Stack>(`orgs/${orgName}/stacks/${stackId}`, stack)
    return res.data
  }

  public static async get(orgName: string, stackId: number): Promise<Stack> {
    const api = await createApi()
    const res = await api.get<Stack>(`orgs/${orgName}/stacks/${stackId}`)
    return res.data
  }
}
