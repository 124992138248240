import { capitalize } from '@/lib/string'

export interface ModuleSubcategory {
  name: string;
  description: string;
}

export default interface ModuleCategory {
  name: string;
  description: string;
  subcategories: ModuleSubcategory[];
}

export const uiSortedCategories: string[] = [
  'app',
  'datastore',
  'domain',
  'subdomain',
  'cluster-namespace',
  'cluster',
  'network',
  'block',
  'capability'
]

export const friendlyName = (categoryName: string): string => {
  const cleansed = categoryName.replace('-', ' ')
  return capitalize(cleansed, true)
}
