import { blankSeries, MetricDataset, MetricSeries } from '@/models/metrics'
import { ChartData } from 'chart.js'

export interface InvocationMetrics {
    name: string
    total: MetricSeries
    errors: MetricSeries
}

export function invocationMetricsToChartData(rawData: MetricDataset) {
  // In an invocations graph, we generate the following:
  // - shaded blue band between x-axis and total
  // - shaded red band between x-axis and errors
  const im = extractMetricsFromDataset(rawData)
  return convertToChartData(im)
}

function extractMetricsFromDataset(ds: MetricDataset): InvocationMetrics {
  const idPrefix = (ds?.name || '').toLowerCase()
  const series = Object.values(ds?.series || {})
  const total = series.find(s => (s.id || '').endsWith('_total')) || blankSeries(`${idPrefix}_total`)
  const errors = series.find(s => (s.id || '').endsWith('_errors')) || blankSeries(`${idPrefix}_errors`)

  return {
    name: ds?.name || '',
    total,
    errors
  }
}

const totalFillColor = 'rgb(190 227 248)'
const totalLineColor = 'rgb(43 108 176)'
const errorsFillColor = 'rgb(254 215 215)'
const errorsLineColor = 'rgb(197 48 48)'

function convertToChartData(im: InvocationMetrics) {
  const cd: ChartData<'line', number[], Date> = {
    datasets: []
  }
  cd.datasets.push({
    label: 'Total',
    data: im.total.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 4,
    backgroundColor: totalFillColor,
    pointBackgroundColor: totalLineColor,
    borderColor: totalLineColor,
    fill: 'origin',
    tension: 0.3
  })
  cd.datasets.push({
    label: 'Errors',
    data: im.errors.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
    borderWidth: 1,
    pointRadius: 0,
    pointHoverRadius: 2,
    backgroundColor: errorsFillColor,
    pointBackgroundColor: errorsFillColor,
    borderColor: errorsLineColor,
    fill: 'origin',
    tension: 0.3
  })
  return cd
}
