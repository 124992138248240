import ModuleCategory, { ModuleSubcategory } from '@/models/module_category'
import Module, { moduleConnections } from '@/models/module'
import { Dictionary } from '@/models/dictionary'
import { ModuleManifestConnection } from '@/models/module_manifest'
import { matchContract, parseModuleContractName } from '@/models/module_contract_name'

export interface ModuleFilterFunc {
  (module: Module | undefined): boolean
}

export function byProvider(providerType: string | undefined, required = false): ModuleFilterFunc {
  return module => (!required && providerType === undefined) || (module?.providerTypes || []).includes(providerType || '')
}
export function byCategory(categoryName: string | undefined): ModuleFilterFunc {
  return module => categoryName === undefined || categoryName === 'all' || module?.category === categoryName
}
export function bySubcategory(subcategoryName: string | undefined): ModuleFilterFunc {
  return module => subcategoryName === undefined || subcategoryName === 'all' || module?.subcategory === subcategoryName
}
export function isExistingModule(existing: boolean): ModuleFilterFunc {
  // we are excluding the module for connecting to an existing network
  // is there a better way to do this?
  return module => ((module?.name || '').indexOf('existing') > -1) === existing
}
export function byConnection(conn: ModuleManifestConnection): ModuleFilterFunc {
  if (conn.contract && conn.contract !== '*/*/*') {
    const cn = parseModuleContractName(conn.contract)
    return module => matchContract(cn, module)
  }
  return module => module?.type === conn.type
}
export function bySource(source: string): ModuleFilterFunc {
  return module => `${module?.orgName}/${module?.name}` === source
}

/**
 * Filter a capability module based on whether the input module satisfies any of the capability's connections
 * @param module A module that is tested to satisfy a capability's connection
 */
export function byCapabilityConnections(module: Module | undefined): ModuleFilterFunc {
  if (!module) {
    return () => false
  }
  return capModule => Object.values(moduleConnections(capModule))
    .map(byConnection)
    .some(filter => filter(module))
}

/**
 * Filter a module based on whether any of its connections satisfy the input connection
 * @param conn The connection contract that is tested to satisfy any of the connections
 */
export function byModuleConnections(conn: ModuleManifestConnection): ModuleFilterFunc {
  const want = parseModuleContractName(conn.contract)
  return module => Object.values(moduleConnections(module))
    .some(c => {
      if (c.contract && c.contract !== '*/*/*') {
        return matchContract(want, c.contract)
      }
      return conn.type === c.type
    })
}

export function byCapabilityAppSubcategory(subcategory: string | undefined): ModuleFilterFunc {
  return capModule => (capModule?.appCategories || []).includes(subcategory || '')
}

export function isCapabilityModule(module: Module | undefined): boolean {
  return (module?.category || '').startsWith('capability')
}

export function getSubcategories(categories: ModuleCategory[], categoryName: string): ModuleSubcategory[] {
  const appCategory = categories.find(category => category.name === categoryName)
  return (appCategory?.subcategories || [])
}

export function groupBySubcategory(modules: Module[]): Dictionary<Module[]> {
  return modules.reduce((result, module) => {
    const grp = result[module.subcategory] || []
    grp.push(module)
    result[module.subcategory] = grp
    return result
  }, {} as Dictionary<Module[]>)
}
