import { ref } from 'vue'
import User from '@/models/user'
import cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Dictionary } from '@/models/dictionary'

export interface Token {
  sub: string;
  exp: number;
  iat: number;
  email: string;
  name: string;
  picture: string;
  'https://nullstone.io/username': string;
  'https://nullstone.io/roles': Dictionary<string>
  'https://nullstone.io/org_plans': Dictionary<string>
}

export const env = ref('')

// NOTE: None of the members of this composable are computed.
// This is because the underlying data comes from a cookie and isn't reactive.
const tokenName = () => {
  return `${env.value}_nullstone_user`
}

export function emptyToken(): Token {
  return {
    sub: '',
    exp: 0,
    iat: 0,
    email: '',
    name: '',
    picture: '',
    'https://nullstone.io/username': '',
    'https://nullstone.io/roles': {},
    'https://nullstone.io/org_plans': {}
  }
}

export const getIdToken = (): Token => {
  const rawToken = cookie.get(tokenName()) || ''
  if (rawToken) {
    return jwtDecode<Token>(rawToken)
  } else {
    return emptyToken()
  }
}

export const getCurrentUser = (): User => {
  const token = getIdToken()
  // fetching name from the name field satisfies existing tokens
  // https://nullstone.io/username is the new (and jwt standard) way
  // TODO: as soon as we don't need to account for any old tokens, remove the name field from Token
  const name = token.name || token['https://nullstone.io/username']
  return new User(token.sub, name, token.email, token.picture, token['https://nullstone.io/roles'])
}
