<template>
  <n-dialog v-model:open="openWrapper">
    <div class="p-6 text-center w-128">
      <div class="text-lg font-bold">{{ actionName }} {{ itemName }}?</div>
      <div class="mt-2 text-left px-10">
        <slot></slot>
        <template v-if="confirmationText">
          <p class="mt-4">To confirm, type "<span class="font-bold">{{ confirmationText }}</span>" below.</p>
          <n-text-input v-model="confirmDestroyInput" class="mt-4"></n-text-input>
        </template>
      </div>
      <div class="flex flex-row justify-center mt-4">
        <n-button type="secondary" @click="openWrapper = false">Cancel</n-button>
        <n-button :type="actionType" class="ml-6" :disabled="!confirmed || confirmClicked" :working="confirmClicked"
                  @click.prevent="confirm">{{ actionName }}</n-button>
      </div>
    </div>
  </n-dialog>
</template>

<script setup lang="ts">
import modelWrapper from '@/lib/modelWrapper'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  actionName: {
    type: String,
    default: 'Destroy'
  },
  actionType: {
    type: String,
    default: 'danger'
  },
  itemName: {
    type: String,
    required: true
  },
  confirmationText: {
    type: String
  }
})
const emit = defineEmits(['confirmDestroy', 'update:open'])

const openWrapper = modelWrapper<boolean>(props, emit, 'open')
const confirmClicked = ref(false)

watch(openWrapper, (open) => {
  if (!open) {
    confirmDestroyInput.value = ''
    confirmClicked.value = false
  }
})

const confirmDestroyInput = ref('')
const confirmed = computed(() => {
  if (!props.confirmationText) {
    return true
  }
  return confirmDestroyInput.value === props.confirmationText
})
const confirm = () => {
  confirmClicked.value = true
  emit('confirmDestroy')
}
</script>
