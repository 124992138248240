<template>
  <div v-if="size === 'regular'" class="border-2 border-dashed rounded border-brownish-gray-300 bg-brownish-gray-100/50 p-10 flex flex-col items-center justify-center">
    <n-icon :name="iconName" scale="2.5" class="text-brownish-gray-400"></n-icon>
    <h3 class="mt-2 font-semibold">{{ title }}</h3>
    <div class="mt-2 text-center text-brownish-gray-500 max-w-sm">
      <slot></slot>
    </div>
  </div>
  <div v-else-if="size === 'small'" class="p-6 border-2 border-dashed rounded border-brownish-gray-300 bg-brownish-gray-100/50">
    <div class="flex flex-row items-start gap-x-4">
      <n-icon :name="iconName" scale="2" class="text-brownish-gray-400"></n-icon>
      <div>
        <h4 class="mr-10 mt-1 font-semibold">{{ title }}</h4>
        <div class="text-brownish-gray-500 max-w-sm">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export enum EmptySize {
  Small = 'small',
  Regular = 'regular',
  Large = 'large'
}

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    size: {
      type: String as () => EmptySize,
      default: EmptySize.Regular
    }
  }
})
</script>
