export enum AwsAuthTypes {
  accessKeys = 'accessKeys',
  assumeRole = 'assumeRole'
}

export const awsAuthTypeNames: Record<string, string> = {
  accessKeys: 'Access Keys',
  assumeRole: 'Assume Role'
}

export const nullstoneAwsAssumeRoleAccountId = '875119311270'

export default class AwsCredentials {
  authType = ''
  accessKeyId?: string
  secretAccessKey?: string
  assumeRoleName?: string
  assumeRoleExternalId?: string
}

export function generateAwsAssumeRoleCredentials(): AwsCredentials {
  const creds = new AwsCredentials()
  creds.authType = AwsAuthTypes.assumeRole
  creds.assumeRoleName = 'nullstone'
  creds.assumeRoleExternalId = generateRandomExternalId()
  return creds
}

export function generateRandomExternalId(): string {
  return `${random(8)}-${random(8)}-${random(8)}`
}

function random(length = 8): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return str
}
