import ApiError from '@/services/api_error'
import notificationsStore, { NotificationTypes } from '@/store/notifications'
import errorLogger from '@/lib/error_logger'
import ApiNotAuthenticatedError from '@/services/api_not_authenticated_error'
import router from '@/router'
import ApiNotAuthorizedError from '@/services/api_not_authorized_error'

function notifyApiError(apiError: ApiError) {
  let details: string[]
  if (apiError instanceof ApiNotAuthenticatedError) {
    router.push({ name: 'logout' })
    return
  } else if (apiError instanceof ApiNotAuthorizedError) {
    router.push({ name: 'no_access' })
    return
  } else {
    details = apiError.errorMessages
  }
  notificationsStore.add({ type: NotificationTypes.error, message: apiError.message, details })
}

const errorHandler = (err: unknown): void => {
  errorLogger.log(err as Error)
  if (err instanceof ApiError) {
    notifyApiError(err)
  }
}

export default errorHandler
