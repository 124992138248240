<template>
  <!--
    TODO: this uses the HeadlessUI Menu directly
    this should be refactored to use a Dropdown that has a lot of this defaulted
  -->
  <h-menu as="div" class="relative border-t border-b border-gray-700">
    <h-menu-button class="block w-full focus:outline-none hover:bg-gray-700">
      <div class="px-4 py-2 text-brownish-gray-500 flex flex-row items-center justify-between">
        <div class="flex flex-col items-start">
          <span class="text-xs">organization</span>
          <span class="text-brownish-gray-200">{{ currentOrg }}</span>
        </div>
        <div class="flex flex-col justify-between">
          <n-icon flip="vertical" name="chevron-down"></n-icon>
          <n-icon name="chevron-down"></n-icon>
        </div>
      </div>
    </h-menu-button>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <h-menu-items class="absolute z-50 ml-2 mt-2.5 bg-white border rounded border-brownish-gray-300 focus:outline-none shadow-md">
        <div class="min-w-80">
          <div class="mx-4 my-6 flex flex-row items-center justify-between">
            <div>
              <n-icon name="fas/sitemap" class="mr-2"></n-icon>
              Organizations
            </div>
            <h-menu-item class="focus:outline-none" v-slot="{ active }">
              <a href="#" @click.prevent="newOrg" class="p-1 rounded text-xs underline" :class="active ? 'ring': ''">
                Create New
              </a>
            </h-menu-item>
          </div>
          <div v-for="org in orgs" :key="org.name" class="flex flex-row items-center border-t border-brownish-gray-300 cursor-pointer select-none">
            <h-menu-item class="focus:outline-none" v-slot="{ active }">
              <a class="w-full px-4 py-2" :class="active ? 'bg-brownish-gray-100' : ''"
                 @click="org.name !== currentOrg ? switchOrg(org.name) : null">
                <div :class="org.name === currentOrg ? 'text-blue-700 font-bold' : ''">{{ org.name }} <span class="font-normal text-brownish-gray-400 text-xs" v-if="org.name === currentUser.name">(personal)</span></div>
              </a>
            </h-menu-item>
            <h-menu-item class="focus:outline-none" v-slot="{ active }">
              <div class="px-4 py-2" @click="editOrg(org.name)" :class="active ? 'bg-brownish-gray-100' : ''">
                <n-icon name="fas/cog"></n-icon>
              </div>
            </h-menu-item>
          </div>
        </div>
      </h-menu-items>
    </transition>
  </h-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import orgStore from '@/store/orgs'
import { Menu as HMenu, MenuButton as HMenuButton, MenuItems as HMenuItems, MenuItem as HMenuItem } from '@headlessui/vue'
import { getCurrentUser } from '@/lib/use_current_user'

const route = useRoute()
const router = useRouter()
const currentUser = getCurrentUser()

const orgs = computed(() => orgStore.organizations)
const currentOrg = computed(() => route.params.orgName || orgStore.currentOrg)

const newOrg = () => {
  router.push({ name: 'new_organization' })
}
const editOrg = (orgName: string) => {
  router.push({ name: 'organization_members', params: { orgName } })
}

const switchOrg = (orgName: string) => {
  if (route.name === 'home') {
    window.location.href = `/orgs/${orgName}`
  } else {
    router.push({ name: 'home', params: { orgName } })
  }
}
</script>
