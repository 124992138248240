<template>
  <div class="border rounded p-2" :class="`${borderColor} ${bgColor}`">
    <div class="flex flex-row items-start">
      <n-icon v-if="icon" :name="icon" class="mt-1 mr-2" :class="iconColor"></n-icon>
      <div class="w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export enum InfoType {
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  DEFAULT = 'default',
  SUCCESS = 'success',
  HIGHLIGHT = 'highlight'
}

export default defineComponent({
  name: 'InfoComponent',
  props: {
    type: {
      type: String,
      default: InfoType.DEFAULT
    }
  },
  setup(props) {
    const iconColor = computed(() => {
      switch (props.type) {
      case InfoType.WARNING:
        return 'text-yellow-600'
      case InfoType.INFO:
        return 'text-blue-700'
      case InfoType.ERROR:
        return 'text-red-700'
      case InfoType.SUCCESS:
        return 'text-green-700'
      case InfoType.HIGHLIGHT:
        return 'text-brownish-gray-300'
      default:
        return 'text-brownish-gray-300'
      }
    })

    const borderColor = computed(() => {
      switch (props.type) {
      case InfoType.WARNING:
        return 'border-yellow-400'
      case InfoType.INFO:
        return 'border-blue-200'
      case InfoType.ERROR:
        return 'border-red-200'
      case InfoType.SUCCESS:
        return 'border-green-700/25'
      case InfoType.HIGHLIGHT:
        return 'border-gray-300'
      default:
        return 'brownish-gray-300'
      }
    })

    const bgColor = computed(() => {
      switch (props.type) {
      case InfoType.WARNING:
        return 'bg-yellow-100'
      case InfoType.INFO:
        return 'bg-blue-100'
      case InfoType.ERROR:
        return 'bg-red-100'
      case InfoType.SUCCESS:
        return 'bg-green-100'
      case InfoType.HIGHLIGHT:
        return 'bg-white'
      default:
        return 'bg-brownish-gray-100'
      }
    })

    const icon = computed(() => {
      switch (props.type) {
      case InfoType.WARNING:
        return 'fas/exclamation-triangle'
      case InfoType.INFO:
        return 'fas/info-circle'
      case InfoType.ERROR:
        return 'fas/times-circle'
      case InfoType.SUCCESS:
        return 'fas/check-circle'
      default:
        return null
      }
    })

    return { iconColor, bgColor, borderColor, icon }
  }
})
</script>
