import { AxiosError } from 'axios'
import { ValidationErrors } from '@/services/api_validation_error'

export interface ApiErrorData {
  // eslint-disable-next-line camelcase
  request_id: string
  title: string,
  type: string,
  code: string,
  error_code?: number,
  message: string,
  // eslint-disable-next-line camelcase
  validation_errors?: ValidationErrors,
  details?: Record<string, string>|string,
  source: string
}

export enum ApiErrorCode {
  // base error codes
  InvalidPathParameter = 10000,
  InvalidQueryParameter = 10001,
  InvalidPayload = 10002,

  // nullfire error codes
  WorkspaceNotProvisioned = 12000,

  // enigma error codes
  LogsNotSupported = 13000,
  MetricsNotSupported = 13001,
}

export interface ApiErrorJson {
  requestId: string
  url: string
  status: string
}

export default class ApiError extends Error {
  public url: string
  public status: number | undefined
  public statusText: string | undefined
  public requestId: string
  public cause: Error
  public errorCode: ApiErrorCode
  public errorMessages: string[]

  constructor(error: AxiosError) {
    super('Api Call Failed')
    this.name = 'ApiError'
    this.url = error.config?.url as string
    this.requestId = error.config?.headers?.['X-Request-Id'] as string
    this.status = error.response?.status
    this.statusText = error.response?.statusText
    this.cause = error

    const data = error.response?.data as ApiErrorData
    this.errorCode = data?.error_code || 0

    const errorMessages = ['Unknown error occurred.']
    if (this.requestId) {
      errorMessages.push(`Request ID: ${this.requestId}`)
    }
    errorMessages.push(this.url)
    if (this.status && this.statusText) {
      errorMessages.push(`${this.status} - ${this.statusText}`)
    }
    this.errorMessages = errorMessages
  }

  get json(): ApiErrorJson {
    return {
      requestId: this.requestId,
      url: this.url,
      status: `${this.status} - ${this.statusText}`
    }
  }
}
