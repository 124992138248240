<template>
  <!--
    TODO: this uses the HeadlessUI Menu directly
    this should be refactored to use a Dropdown that has a lot of this defaulted
  -->
  <h-menu as="div" class="relative">
    <h-menu-button class="border rounded border-brownish-gray-300 hover:bg-brownish-gray-100 w-5 h-5 flex flex-row items-center justify-center">
      <n-icon name="chevron-down" scale="1.2" class="text-brownish-gray-600"></n-icon>
    </h-menu-button>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <h-menu-items class="absolute z-50 mt-2 bg-white border rounded border-brownish-gray-300 focus:outline-none shadow-md">
        <div class="min-w-96">
          <div class="px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">environments</div>
          <template v-if="envs.length === 0">
            <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
              <n-icon name="environments" class="block text-brownish-gray-300"></n-icon>
              <h3 class="text-brownish-gray-300">No environments</h3>
            </div>
          </template>
          <div v-for="env in envs" :key="env.id" class="border-t border-brownish-gray-300 select-none">
            <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="env.id === envId">
              <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                      :class="[active || env.id === envId ? 'bg-brownish-gray-100' : '', env.id === envId ? 'cursor-default' : 'cursor-pointer']"
                      @click="goToEnv(env.id)">
                <n-icon name="environments" class="text-brownish-gray-400"></n-icon>
                <span :class="env.id === envId ? 'font-semibold' : ''">{{ env.name }}</span>
              </button>
            </h-menu-item>
          </div>
          <template v-if="!isGlobal">
            <div class="px-2 py-1 border-t border-brownish-gray-300 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">preview environments</div>
            <template v-if="previewEnvs.length === 0">
              <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
                <n-icon name="environments" class="block text-brownish-gray-300"></n-icon>
                <h3 class="text-brownish-gray-300">No environments</h3>
              </div>
            </template>
            <div v-for="env in previewEnvs" :key="env.id" class="border-t border-brownish-gray-300 select-none">
              <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="env.id === envId">
                <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                        :class="[active || env.id === envId ? 'bg-brownish-gray-100' : '', env.id === envId ? 'cursor-default' : 'cursor-pointer']"
                        @click="goToEnv(env.id)">
                  <n-icon name="preview-env" class="text-brownish-gray-400"></n-icon>
                  <span :class="env.id === envId ? 'font-semibold' : ''">{{ env.name }}</span>
                </button>
              </h-menu-item>
            </div>
          </template>
        </div>
      </h-menu-items>
    </transition>
  </h-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Menu as HMenu, MenuButton as HMenuButton, MenuItem as HMenuItem, MenuItems as HMenuItems } from '@headlessui/vue'
import envStore from '@/store/envs'
import { EnvironmentType } from '@/models/environment'
import useGlobalStack from '@/lib/use_global_stack'
import { useRoute, useRouter } from 'vue-router'
import routeToEnv from '@/lib/route_to_env'

const props = defineProps<{
  orgName: string
  stackId: number
  envId: number
}>()

const envs = computed(() => envStore.query(props.orgName, props.stackId))
const previewEnvs = computed(() => envStore.query(props.orgName, props.stackId, [EnvironmentType.Preview, EnvironmentType.PreviewsShared]))

const { isGlobal } = useGlobalStack(props.orgName, props.stackId)

const route = useRoute()
const router = useRouter()
const goToEnv = (newEnvId: number) => {
  routeToEnv(route, router, newEnvId)
}
</script>
