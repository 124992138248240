import { getCurrentUser } from '@/lib/use_current_user'

export function authorizeStackSoftwareEngineer(orgName: string, stackId: number): boolean {
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  const currentUser = getCurrentUser()
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  const roleInStack = currentUser.roles[`stack:${stackId}`]
  if (!roleInOrg) { return false }
  if (!roleInStack) { return false }
  return roleInStack === 'software_engineer' || roleInStack === 'architect' || roleInStack === 'owner'
}

export function authorizeStackArchitect(orgName: string, stackId: number): boolean {
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  const currentUser = getCurrentUser()
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  const roleInStack = currentUser.roles[`stack:${stackId}`]
  if (!roleInOrg) { return false }
  if (!roleInStack) { return false }
  return roleInStack === 'architect' || roleInStack === 'owner'
}

export function authorizeStackOwner(orgName: string, stackId: number): boolean {
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  const currentUser = getCurrentUser()
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  const roleInStack = currentUser.roles[`stack:${stackId}`]
  if (!roleInOrg) { return false }
  if (!roleInStack) { return false }
  return roleInStack === 'architect' || roleInStack === 'owner'
}
