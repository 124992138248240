<template>
  <div class="border border-brownish-gray-300 rounded bg-white">
    <div v-if="hasHeader" class="p-2 border-b rounded-t border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-between">
      <slot name="header"/>
    </div>
    <div v-if="status" :class="['h-2', status]"></div>
    <slot/>
    <div v-if="hasActions" class="border-t rounded-b border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-end space-x-3 p-3">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'PanelComponent',
  props: {
    status: String
  },
  setup(props, { slots }) {
    const hasHeader = computed(() => {
      return slots.header
    })

    const hasActions = computed(() => {
      return slots.actions
    })

    return { hasHeader, hasActions }
  }
})
</script>
