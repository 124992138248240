<template>
  <svg aria-hidden="true" focusable="false" :class="classes" :viewBox="viewbox" :width="size" :height="size" v-html="iconContent"></svg>
</template>

<script>
import { defineComponent, computed } from 'vue'

function hasOwn (obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

function assign (obj, ...sources) {
  sources.forEach(source => {
    for (const key in source) {
      if (hasOwn(source, key)) {
        obj[key] = source[key]
      }
    }
  })
  return obj
}

const icons = {}

export default defineComponent({
  name: 'IconComponent',
  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => {
        return (value in icons)
      }
    },
    scale: {
      default: 1,
      type: [Number, String],
      validator: (value) => {
        if (value && (isNaN(value) || value <= 0)) {
          return false
        }
        return true
      }
    },
    spin: Boolean,
    rotate: {
      type: String,
      validator: (value) => {
        return value === '0' || value === '90' || value === '180' || value === '270'
      }
    },
    flip: {
      type: String,
      validator: (value) => {
        return value === 'horizontal' || value === 'vertical' || value === 'both' || value === ''
      }
    }
  },
  setup(props) {
    const icon = computed(() => {
      return icons[props.name]
    })
    const iconContent = computed(() => {
      return icon.value ? icon.value.iconContent : null
    })
    const classes = computed(() => {
      const result = ['fa-icon']
      if (props.spin) { result.push('fa-spin') }
      if (props.rotate) { result.push(`fa-rotate-${props.rotate}`) }
      if (props.flip) { result.push(`fa-flip-${props.flip}`) }
      return result
    })
    const viewbox = computed(() => {
      if (!icon.value) { return '0 0 0 0' }
      return `0 0 ${icon.value.width} ${icon.value.height}`
    })
    const size = computed(() => {
      return `${props.scale}em`
    })
    return { iconContent, classes, viewbox, size }
  },
  registerFA (data) {
    const name = `${data.prefix}/${data.iconName}`
    const iconContent = `<g><path d="${data.icon[4]}"/></g>`
    const icon = { width: data.icon[0], height: data.icon[1], iconContent }
    icons[name] = assign({}, icon)
  },
  registerCustom (data) {
    const name = data.name
    const iconContent = `<g>${data.raw}</g>`
    const icon = { width: data.width, height: data.height, iconContent }
    icons[name] = assign({}, icon)
  }
})
</script>

<style>
.fa-icon {
  display: inline-block;
  fill: currentColor;
  overflow: visible;
  vertical-align: -0.125em;
}
.fa-icon > g {
  transform-origin: 50% 50%;
}
.fa-flip-horizontal {
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  transform: scale(1, -1);
}
.fa-flip-both {
  transform: scale(-1, -1);
}
.fa-rotate-0 { }
.fa-rotate-90 {
  transform: rotate(90deg);
}
.fa-rotate-180 {
  transform: rotate(180deg);
}
.fa-rotate-270 {
  transform: rotate(270deg);
}
.fa-spin > g {
  animation: fa-spin 1s 0s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
