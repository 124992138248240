import Organization from '@/models/organization'
import { createAuth } from '@/services/api'

export default class OrganizationService {
  public static async get(orgName: string): Promise<Organization> {
    const auth = await createAuth()
    const res = await auth.get<Organization>(`/orgs/${orgName}`)
    return res.data
  }

  public static async query(): Promise<Organization[]> {
    const auth = await createAuth()
    const res = await auth.get<Organization[]>('/orgs')
    return res.data
  }

  public static async create(org: Organization, personal = false): Promise<Organization> {
    const auth = await createAuth()
    const res = await auth.post<Organization>('/orgs', { organization: org, personal })
    return res.data
  }
}
