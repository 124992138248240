import { createApi } from '@/services/api'
import Environment from '@/models/environment'

export default class EnvironmentService {
  public static async query(orgName: string, stackId: number): Promise<Environment[]> {
    const api = await createApi()
    const res = await api.get<Environment[]>(`orgs/${orgName}/stacks/${stackId}/envs`)
    res.data.sort((a, b) => {
      // if the pipelineOrder is ever blank, it goes to the back of the line
      const firstOrder = a.pipelineOrder || 99
      const lastOrder = b.pipelineOrder || 99
      return firstOrder - lastOrder
    })
    return res.data
  }

  public static async get(orgName: string, stackId: number, envId: number): Promise<Environment> {
    const api = await createApi()
    const res = await api.get<Environment>(`orgs/${orgName}/stacks/${stackId}/envs/${envId}`)
    return res.data
  }

  public static async create(orgName: string, stackId: number, env: Environment): Promise<Environment> {
    const api = await createApi()
    const res = await api.post<Environment>(`orgs/${orgName}/stacks/${stackId}/envs`, env)
    return res.data
  }

  public static async delete(orgName: string, stackId: number, envId: number): Promise<null> {
    const api = await createApi()
    return await api.delete(`orgs/${orgName}/stacks/${stackId}/envs/${envId}`)
  }

  public static async update(orgName: string, stackId: number, envId: number, env: Environment): Promise<Environment> {
    const api = await createApi()
    const res = await api.put<Environment>(`orgs/${orgName}/stacks/${stackId}/envs/${envId}`, env)
    return res.data
  }
}
