import { blankSeries, MetricDataset, MetricSeries } from '@/models/metrics'
import { ChartData } from 'chart.js'
import '@/lib/chartjs'

export interface UsageMetrics {
  name: string
  reserved: MetricSeries
  avg: MetricSeries
  min: MetricSeries
  max: MetricSeries
}

export function usageMetricsToChartData(rawData: MetricDataset, includeReserved: boolean) {
  // In a usage graph, we generate the following:
  // - shaded band between the min and max
  // - average line trending through the shaded area
  // - all of these values are represented as a percentage of the "reserved"
  const usageMetrics = extractUsageMetricsFromDataset(rawData)
  return convertUsageMetricsToChartData(usageMetrics, includeReserved)
}

export function extractUsageMetricsFromDataset(ds: MetricDataset): UsageMetrics {
  const idPrefix = (ds?.name || '').toLowerCase()
  const series = Object.values(ds?.series || {})
  const reserved = series.find(s => (s.id || '').endsWith('_reserved')) || blankSeries(`${idPrefix}_reserved`)
  const avg = series.find(s => (s.id || '').endsWith('_average')) || blankSeries(`${idPrefix}_average`)
  const min = series.find(s => (s.id || '').endsWith('_min')) || blankSeries(`${idPrefix}_min`)
  const max = series.find(s => (s.id || '').endsWith('_max')) || blankSeries(`${idPrefix}_max`)

  return {
    name: ds?.name || '',
    reserved,
    avg,
    min,
    max
  }
}

const usageFillColor = 'rgb(190 227 248)'
const usageLineColor = 'rgb(43 108 176)'

export function convertUsageMetricsToChartData(um: UsageMetrics, includeReserved: boolean) {
  const cd: ChartData<'line', number[], Date> = {
    datasets: []
  }
  if (includeReserved) {
    cd.datasets.push({
      label: 'Reserved',
      data: um.reserved.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      fill: false
    })
  }
  cd.datasets.push({
    label: 'Average',
    data: um.avg.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
    borderWidth: 2,
    pointRadius: 0,
    pointHoverRadius: 4,
    backgroundColor: usageFillColor,
    pointBackgroundColor: usageLineColor,
    borderColor: usageLineColor,
    fill: false,
    tension: 0.3
  })
  cd.datasets.push({
    label: 'Min',
    data: um.min.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
    borderWidth: 0,
    pointRadius: 0,
    pointHoverRadius: 2,
    backgroundColor: usageFillColor,
    pointBackgroundColor: usageLineColor,
    borderColor: usageLineColor,
    fill: false,
    tension: 0.3
  })
  cd.datasets.push({
    label: 'Max',
    data: um.max.datapoints.map(dp => ({ ...dp, ...{ x: dp.t, y: dp.v } })) as never,
    borderWidth: 0,
    pointRadius: 0,
    pointHoverRadius: 2,
    backgroundColor: usageFillColor,
    pointBackgroundColor: usageLineColor,
    borderColor: usageLineColor,
    fill: '-1',
    tension: 0.3
  })
  return cd
}
