<template>
  <div role="presentation"
      class="select-none rounded-t border-b-2"
      :class="active ? 'border-blue-700' : 'border-transparent hover:border-blue-400'">
    <router-link class="min-w-24 px-2 py-2 whitespace-nowrap flex flex-row items-center justify-center gap-x-2 font-semibold"
      :to="to" :class="active ? 'text-blue-700' : ''">
      <slot :active="active" />
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  to: {
    type: Object,
    required: true
  }
})

const route = useRoute()
const active = computed(() => {
  if (props.to?.name) {
    return route.matched.map(m => m.name).includes(props.to?.name)
  }
  return false
})
</script>
