import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["rounded focus:outline-none select-none whitespace-nowrap", [_ctx.enabledStyles, _ctx.sizeStyles, _ctx.typeStyles]])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.working)
      ? (_openBlock(), _createBlock(_component_n_icon, {
          key: 0,
          name: "fas/sync-alt",
          spin: true,
          class: "ml-4"
        }))
      : _createCommentVNode("", true)
  ], 2))
}