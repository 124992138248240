<template>
  <div class="relative">
    <transition-group tag="div" class="absolute overflow-hidden p-6 right-0 top-0 w-full max-w-lg flex flex-col space-y-2 z-50"
      enter-from-class="translate-x-full opacity-0"
      enter-active-class="duration-500"
      leave-active-class="duration-500"
      leave-to-class="translate-x-full opacity-0">
      <n-toast v-for="notification in notifications" :key="notification.key" class="toast-item"
               :notification="notification" @remove-notification="removeNotification"></n-toast>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Toast from '@/components/base/Toast.vue'
import notificationsStore, { Notification } from '@/store/notifications'

export default defineComponent({
  name: 'ToastTargetComponent',
  components: { 'n-toast': Toast },
  setup() {
    const notifications = computed(() => {
      return notificationsStore.notifications
    })

    const removeNotification = (notification: Notification) => {
      notificationsStore.remove(notification)
    }

    return { notifications, removeNotification }
  }
})
</script>
