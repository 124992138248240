<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row items-end gap-x-1">
      <span class="text-brownish-gray-700 font-semibold">Loading</span>
      <div class="flex flex-row gap-x-1 mb-1">
        <div class="bg-brownish-gray-600 w-1 h-1 rounded-full animate-bounce"></div>
        <div class="bg-brownish-gray-600 w-1 h-1 rounded-full animate-bounce" style="animation-delay: 100ms;"></div>
        <div class="bg-brownish-gray-600 w-1 h-1 rounded-full animate-bounce" style="animation-delay: 200ms;"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingComponent',
  setup() {
    return { }
  }
})
</script>
