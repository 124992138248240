import { RouteLocationNormalized, Router } from 'vue-router'

const routeToStack = (route: RouteLocationNormalized, router: Router, newStackId: number) => {
  // if we are on any of the stack child pages, stay on the same page and just update the stackId
  // otherwise, go to the show_stack page
  const routeName = route.matched[0].name === 'show_stack' ? route.name as string : 'show_stack'
  router.push({ name: routeName as string, params: { orgName: route.params.orgName, stackId: newStackId } })
}

export default routeToStack
