import { RouteLocationNormalized, Router } from 'vue-router'

const routePrefix = (routeName: string) => {
  return routeName.split('_')[0]
}

const calcRouteName = (route: RouteLocationNormalized) => {
  // check to see if we are on a run or deploy
  // if it is, we need to go to the activity page instead
  const isActivityPage = route.matched.find(r => {
    return (r.name as string).endsWith('env_workflow')
  })
  if (isActivityPage) {
    return `${routePrefix(route.name as string)}_env_activity`
  }

  return route.name as string
}

const routeToEnv = (route: RouteLocationNormalized, router: Router, newEnvId: number) => {
  const params = { ...route.params }
  const routeName = calcRouteName(route)
  params.envId = newEnvId.toString()

  router.push({ name: routeName, params })
}

export default routeToEnv
