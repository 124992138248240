import Module from '@/models/module'

export interface ModuleContractName {
  category: string;
  subcategory: string;
  provider: string;
  platform: string;
  subplatform: string;
}

export function serializeModuleContractName(cn: ModuleContractName): string {
  let fullCategory = cn.category
  if (cn.subcategory !== '') {
    fullCategory = `${cn.category}:${cn.subcategory}`
  }
  let fullPlatform = cn.platform
  if (cn.subplatform !== '') {
    fullPlatform = `${cn.platform}:${cn.subplatform}`
  }
  return `${fullCategory}/${cn.provider}/${fullPlatform}`
}

export function parseModuleContractName(s: string): ModuleContractName {
  const cn = {
    category: '',
    subcategory: '',
    provider: '',
    platform: '',
    subplatform: ''
  } as ModuleContractName
  if (!s) {
    return cn
  }
  const tokens = s.split('/', 3)
  if (tokens.length !== 3) {
    throw new Error('Invalid module contract format, expected <category>/<provider>/<platform>')
  }
  [cn.category, cn.provider, cn.platform] = tokens
  if (cn.category.indexOf(':') > -1) {
    [cn.category, cn.subcategory] = cn.category.split(':', 2)
    cn.subcategory = cn.subcategory || ''
  }
  if (cn.platform.indexOf(':') > -1) {
    [cn.platform, cn.subplatform] = cn.platform.split(':', 2)
    cn.subplatform = cn.subplatform || ''
  }
  return cn
}

/**
 * Performs wildcard matching of module contracts
 * A module contract name has the format: <category>[:<subcategory>]/<provider>/<platform>[:<subplatform>]
 * Wildcard matching is supported by providing '*' for a component
 * If an optional component is not specified, it is implied to be '*'
 * @param contract The contract name match from the module definition. Contains wildcards in the component to match "any" value.
 * @param test The contract name to perform matching against. This should not contain wildcards.
 */
export function matchContract(contract: ModuleContractName, test: string | Module | ModuleContractName | undefined): boolean {
  if (!test) { return false }
  let other: ModuleContractName
  if (typeof test === 'string') {
    other = parseModuleContractName(test)
  } else {
    // test could be Module or ModuleContractName
    // The only difference is that Module contains 'providerTypes' instead of 'provider'
    other = {
      category: test.category,
      subcategory: test.subcategory,
      provider: (test as ModuleContractName).provider || '',
      platform: test.platform,
      subplatform: test.subplatform
    } as ModuleContractName

    const tm = test as Module
    if (tm.providerTypes) {
      other.provider = tm.providerTypes.length > 0 ? tm.providerTypes[0] : ''
    }
    // Perform provider testing outside of contract matching because the module can support multiple providers
    // If the contract doesn't have limitations, we're just going to rely on the default of the first provider (set above)
    if (contract.provider && contract.provider !== '*') {
      other.provider = (tm.providerTypes || []).find(pt => pt === contract.provider) || ''
    }
  }
  return matchContractPart(contract.category, other.category, false) &&
    matchContractPart(contract.subcategory, other.subcategory, true) &&
    matchContractPart(contract.provider, other.provider, false) &&
    matchContractPart(contract.platform, other.platform, false) &&
    matchContractPart(contract.subplatform, other.subplatform, true)
}

function matchContractPart(want: string, got: string, optional: boolean): boolean {
  if (want === '*') {
    return true
  }
  if (want === '' && optional) {
    return true
  }
  return want === got
}
