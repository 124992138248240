import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680f4f02"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "container",
  class: "relative overflow-x-hidden px-4"
}
const _hoisted_2 = { class: "absolute inset-0 z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "bar",
      class: _normalizeClass(["absolute inset-0 opacity-75", _ctx.bgColor])
    }, null, 2),
    _createElementVNode("div", {
      id: "line",
      class: _normalizeClass(["inset-0 absolute z-10", [_ctx.bgColor, _ctx.active ? 'dec' : '']])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}