import { VuexModule, Module as VCModule, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import Block, { sortBlocksByCategoryThenName } from '@/models/block'
import { Dictionary } from '@/models/dictionary'
import BlockService from '@/services/block_service'

@VCModule
class BlocksModule extends VuexModule {
  blocks = {} as Dictionary<Block[]>

  get query() {
    return (orgName: string, stackId: number): Block[] => {
      const blocks = this.blocks[this.key(orgName, stackId)] || []
      return sortBlocksByCategoryThenName(orgName, blocks)
    }
  }

  get find() {
    return (orgName: string, stackId: number, blockId: number): Block | undefined => {
      return (this.blocks[this.key(orgName, stackId)] || []).find(b => b.id === blockId)
    }
  }

  private key(orgName: string, stackId: number): string {
    return `${orgName}:${stackId}`
  }

  @Action
  async loadBlocks({ orgName, stackId }: { orgName: string, stackId: number }) {
    const results = await BlockService.query(orgName, stackId)
    this.setBlocks({ orgName, stackId, blocks: results })
  }

  @Action
  async removeBlock(block: Block) {
    await BlockService.delete(block.orgName, block.stackId, block.id)
    this.deleteBlock(block)
  }

  @Action
  async updateBlock(block: Block) {
    await BlockService.update(block.orgName, block.stackId, block)
    this.upsertBlock(block)
  }

  @Mutation
  addBlock(block: Block) {
    const key = this.key(block.orgName, block.stackId)
    if (!this.blocks[key]) {
      this.blocks[key] = []
    }
    this.blocks[key].push(block)
  }

  @Mutation
  upsertBlock(block: Block) {
    const key = this.key(block.orgName, block.stackId)
    if (!this.blocks[key]) {
      this.blocks[key] = []
    }
    const existing = this.blocks[key].find(ds => ds.id === block.id)
    if (existing) {
      Object.assign(existing, block)
    } else {
      this.blocks[key].push(block)
    }
  }

  @Mutation
  setBlocks({ orgName, stackId, blocks }: { orgName: string, stackId: number, blocks: Block[] }) {
    this.blocks[this.key(orgName, stackId)] = blocks
  }

  private deleteBlock(block: Block) {
    const index = (this.blocks[this.key(block.orgName, block.stackId)] || []).findIndex(b => b.id === block.id)
    if (index > -1) {
      this.blocks[this.key(block.orgName, block.stackId)].splice(index, 1)
    }
  }
}

export default new BlocksModule({ store, name: 'blocks' })
