<template>
  <div class="absolute inset-0 flex flex-row">
    <div id="sidebar" class="shrink-0 flex flex-col px-3 pb-3 bg-gray-800 w-64">
      <div class="h-14 flex items-center justify-center border-b border-gray-700 -mx-3">
        <n-icon name="nullstone/full" scale="10" class="mr-2 text-blue-300"></n-icon>
      </div>
      <div class="border-gray-100 px-3 mt-6 mb-2 flex flex-row items-center text-brownish-gray-500">
        <span class="text-xs">Organizations</span>
      </div>
      <router-link v-for="org in orgs" :key="org.name" :to="{ name: 'home', params: { orgName: org.name } }"
         class="px-3 py-2 rounded hover:bg-gray-700 focus:outline-none focus:ring flex flex-row items-center"
         :class="isCurrentOrg(org.name) ? 'bg-gray-700 text-white' : 'text-brownish-gray-400'">
        <n-icon name="fas/sitemap" class="mr-2"></n-icon>
        <span class="inline-block">{{ org.name }}</span>
      </router-link>
      <div v-if="newOrg" class="px-3 py-2 rounded hover:bg-gray-700 text-white bg-gray-700 focus:outline-none focus:ring flex flex-row items-center">
        <n-icon name="fas/plus" scale=".8" class="mr-2"></n-icon>
        <span class="inline-block">Join Organization</span>
      </div>
      <div v-else-if="isCurrentOrg(undefined)" class="px-3 py-2 rounded hover:bg-gray-700 text-white bg-gray-700 focus:outline-none focus:ring flex flex-row items-center">
        <n-icon name="fas/plus" scale=".8" class="mr-2"></n-icon>
        <span class="inline-block">Create Organization</span>
      </div>
    </div>
    <div class="flex flex-col flex-1">
      <div id="header" class="z-20 h-14 bg-white relative flex border-solid border-gray-400 shadow-md justify-end items-center">
        <h1 class="ml-4 md:ml-8 mr-auto text-lg font-bold">{{ title }}</h1>
        <n-the-user-menu class="ml-2 md:ml-4 mr-4 md:mr-8"></n-the-user-menu>
      </div>
      <n-toast-target></n-toast-target>
      <div id="container" class="flex-1 relative overflow-y-auto">
        <div id="content" class="p-4 md:p-8">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import NTheUserMenu from '@/components/TheUserMenu.vue'
import NToastTarget from '@/components/base/ToastTarget.vue'
import orgStore from '@/store/orgs'

const route = useRoute()
const title = computed(() => route.meta.title)
const currentOrgName = computed(() => {
  return route.params.orgName
})
// this param is populated when a user is accepting an invite to join an organization
const newOrg = computed(() => {
  return route.params.newOrg
})

const orgs = computed(() => {
  return orgStore.organizations
})

const isCurrentOrg = (orgName: string | undefined): boolean => {
  return orgName === currentOrgName.value
}
</script>
