import { PreviewApp } from '@/models/preview_app'
import { createApi } from '@/services/api'

export default class PRPreviewAppsService {
  public static async query(orgName: string, stackId: number, pullRequestId: number): Promise<PreviewApp[]> {
    const api = await createApi()
    const res = await api.get<PreviewApp[]>(`/orgs/${orgName}/stacks/${stackId}/pull_requests/${pullRequestId}/preview_apps`)
    return res.data
  }
}
