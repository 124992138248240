import { AxiosError } from 'axios'
import ApiError from '@/services/api_error'

export default class ApiNotAuthorizedError extends ApiError {
  constructor(error: AxiosError) {
    super(error)
    this.name = 'ApiNotAuthorizedError'
    this.message = 'Not Authorized'
    this.errorMessages = ['User is not authorized.']
  }
}
