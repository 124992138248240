import { createApi } from '@/services/api'
import Module from '@/models/module'

export default class ModuleService {
  public static async listForUser(): Promise<Module[]> {
    const api = await createApi()
    const res = await api.get<Module[]>('modules')
    return res.data
  }

  public static async query(orgName: string): Promise<Module[]> {
    const api = await createApi()
    const res = await api.get<Module[]>(`orgs/${orgName}/modules`)
    return res.data
  }

  public static async get(orgName: string, moduleName: string): Promise<Module> {
    const api = await createApi()
    const res = await api.get<Module>(`orgs/${orgName}/modules/${moduleName}`)
    return res.data
  }

  public static async create(orgName: string, module: Module): Promise<Module> {
    const api = await createApi()
    const res = await api.post<Module>(`orgs/${orgName}/modules`, module)
    return res.data
  }

  public static async update(orgName: string, module: Module): Promise<Module> {
    const api = await createApi()
    const res = await api.put<Module>(`orgs/${orgName}/modules/${module.name}`, module)
    return res.data
  }
}
