<template>
  <div class="flex flex-row items-center"
    :class="disabled ? 'opacity-50': ''">
    <div class="relative" @click="toggle" :class="disabled ? '': 'cursor-pointer'">
      <input :checked="modelValue" ref="checkboxRef" type="checkbox" @focus="onFocused" @blur="onBlurred" :disabled="disabled"
             class="absolute opacity-0"/>
      <div class="flex flex-row items-center">
        <div class="w-4 h-2 m-2 shrink-0 rounded-full shadow-inner opacity-25"
             :class="modelValue ? 'bg-blue-600': 'bg-brownish-gray-600'"></div>
        <div v-if="label" class="ml-2">{{ label }} <span v-if="required">*</span></div>
      </div>
      <div style="top: .35rem;"
           class="absolute w-3 h-3 rounded-full border transform transition-transform duration-200 ease-in-out"
           :class="[
      modelValue ? 'border-transparent bg-blue-600 translate-x-4': 'border-brownish-gray-300 bg-white translate-x-1',
      focused ? 'ring': 'shadow-none'
      ]"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ToggleComponent',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // we initialize checkboxRef with a bogus element because typescript bitches about null otherwise
    // even though we are doing a check for null in the toggle method
    const checkboxRef = ref(document.createElement('input'))
    const focused = ref(false)

    const toggle = () => {
      if (props.disabled) { return }
      if (checkboxRef.value) { checkboxRef.value.focus() } // es-lint-ignore
      const newVal = !checkboxRef.value.checked
      emit('update:modelValue', newVal)
    }

    const onFocused = () => {
      focused.value = true
    }

    const onBlurred = () => {
      focused.value = false
    }

    return { checkboxRef, toggle, focused, onFocused, onBlurred }
  }
})
</script>
