import ModuleVersion from '@/models/module_version'
import moduleStore from '@/store/modules'
import { Dictionary } from '@/models/dictionary'
import { ModuleManifestConnection } from '@/models/module_manifest'
import { matchContract, parseModuleContractName } from '@/models/module_contract_name'
import { uiSortedCategories } from '@/models/module_category'

export default interface Module {
  uid?: string
  orgName: string
  name: string
  friendlyName: string
  description: string
  isPublic: boolean
  createdAt?: Date
  category: string
  subcategory: string
  providerTypes: string[]
  platform: string
  subplatform: string
  type: string
  appCategories: string[]
  status: string
  latestVersion?: ModuleVersion
  sourceUrl?: string
}

export enum ModuleStatus {
  Draft = 'draft',
  Published = 'published',
}

export function blankModule(orgName: string): Module {
  return {
    orgName,
    name: '',
    friendlyName: '',
    description: '',
    isPublic: false,
    category: 'block',
    subcategory: '',
    providerTypes: [],
    platform: '',
    subplatform: '',
    type: '',
    appCategories: [],
    status: ModuleStatus.Published,
    versions: []
  } as Module
}

export function moduleCategory(orgName: string, moduleSource: string): string | undefined {
  return moduleStore.find(orgName, moduleSource)?.category
}

export function moduleSubcategory(orgName: string, moduleSource: string): string | undefined {
  return moduleStore.find(orgName, moduleSource)?.subcategory
}

export function modulePlatform(orgName: string, moduleSource: string): string | undefined {
  return moduleStore.find(orgName, moduleSource)?.platform
}

export function moduleConnections(module: Module|undefined): Dictionary<ModuleManifestConnection> {
  if (!module?.latestVersion) { return {} }
  const version = module.latestVersion
  if (!version) { return {} }
  if (!version.manifest) { return {} }
  return version.manifest.connections
}

export function sortModuleByCategoryThenFriendlyName(m1: Module, m2: Module): number {
  const categoryDiff = uiSortedCategories.indexOf(m1.category) - uiSortedCategories.indexOf(m2.category)
  if (categoryDiff !== 0) return categoryDiff
  if (m1.friendlyName < m2.friendlyName) return -1
  if (m1.friendlyName > m2.friendlyName) return 1
  return 0
}

export function sortByFriendlyName(m1: Module, m2: Module): number {
  if (m1.friendlyName.toLowerCase() > m2.friendlyName.toLowerCase()) { return 1 }
  if (m1.friendlyName.toLowerCase() < m2.friendlyName.toLowerCase()) { return -1 }
  return 0
}

// findModulesByContract returns all modules that match the contract
export const findModulesByContract = (orgName: string, contract: string): Module[] => {
  const parsed = parseModuleContractName(contract)
  return moduleStore.forOrg(orgName).filter(hasVersions).filter(m => matchContract(parsed, m))
}

// findDefaultModuleByContract first tries to find a module that is in the defaultModules list and matches the contract
//   if there is no default, return the first module that matches the contract
export const findDefaultModuleByContract = (orgName: string, contract: string, defaultModules: string[]): Module | undefined => {
  const contractModules = findModulesByContract(orgName, contract)
  const fromDefaults = contractModules.filter(m => defaultModules.includes(`${m.orgName}/${m.name}`))
  if (fromDefaults.length > 1) throw new Error(`Multiple default modules found for ${contract}`)
  if (fromDefaults.length === 1) return fromDefaults[0]

  return contractModules[0]
}

const priorityAppModules: Dictionary<string[]> = {
  aws: ['aws-fargate-service', 'aws-lambda-container-service', 'aws-s3-site', 'aws-ec2'],
  gcp: ['gcp-gke-service']
}
export const sortByPriority = (m1: Module, m2: Module, providerType: string) => {
  if (priorityAppModules[providerType].includes(m1.name)) return -1
  if (priorityAppModules[providerType].includes(m2.name)) return 1
  if (m1.name < m2.name) return -1
  if (m1.name > m2.name) return 1
  return 0
}

export function hasVersions(module: Module): boolean {
  return !!module.latestVersion
}
