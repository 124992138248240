import { createApi } from '@/services/api'
import ModuleCategory from '@/models/module_category'

export default class ModuleCategoriesService {
  public static async query(): Promise<ModuleCategory[]> {
    const api = await createApi()
    const res = await api.get<ModuleCategory[]>('/categories')
    return res.data
  }
}
