<template>
  <div class="relative border rounded flex flex-row bg-white border-brownish-gray-300"
    :class="$attrs.disabled ? 'opacity-50': ''">
    <input ref="input" v-model.trim="value" :type="type" @focus="onFocused" @blur="onBlurred" :disabled="$attrs.disabled"
           :maxlength="$attrs.maxlength" :pattern="$attrs.pattern"
           class="w-full rounded appearance-none focus:outline-none focus:ring px-2"
           :class="[label ? '': 'py-2', '']"
           :style="label ? 'padding-top: 1.05rem; padding-bottom: .1rem;': ''">
    <n-icon v-if="icon" :name="icon" class="absolute right-3 top-3 text-brownish-gray-400"></n-icon>
    <label v-if="label" class="absolute top-0 left-0 pointer-events-none transform origin-top-left duration-300 ease-in-out text-brownish-gray-500 translate-x-2"
           :style="labelUp ? '--tw-scale-x: .75; --tw-scale-y: .75; --tw-translate-y: .2rem; --tw-translate-x: .55rem;': '--tw-translate-y: .55rem;'"
    >{{ label }} <span v-if="required">*</span></label>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import modelWrapper from '@/lib/modelWrapper'
import useFocus from '@/components/base/use_focus'

const props = defineProps({
  modelValue: {
    type: [String, Number]
  },
  label: {
    type: String
  },
  required: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  },
  icon: {
    type: String
  },
  autofocus: {
    type: Boolean
  },
  allowedChars: {
    type: String
  }
})
const emit = defineEmits(['update:modelValue'])

const input = ref(document.createElement('div'))
const value = modelWrapper<string | number>(props, emit)
const hasValue = computed(() => {
  // https://262.ecma-international.org/6.0/#sec-abstract-equality-comparison
  // if value.value is null or undefined then this will return false
  if (value.value == null) return false
  // when value.value isn't null or undefined, check its length to see if it has a value
  return value.value.toString().length > 0
})
const { focused, onFocused, onBlurred } = useFocus()

onMounted(() => {
  if (props.autofocus) {
    input.value.focus()
  }
  if (props.allowedChars) {
    input.value.addEventListener('keypress', (e) => {
      const regex = new RegExp(props.allowedChars as string)
      if (!e.key.match(regex)) {
        e.preventDefault()
      }
    })
  }
})

const labelUp = computed(() => {
  return hasValue.value || focused.value
})
</script>
