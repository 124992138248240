import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute inset-0 flex justify-center items-center"
}
const _hoisted_2 = {
  key: 0,
  class: "border-t border-b border-red-700 bg-red-100 text-red-700 text-xs p-6"
}
const _hoisted_3 = { class: "text-xs text-red-700 list-disc ml-4" }
const _hoisted_4 = {
  key: 1,
  class: "rounded-b border-t border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-end space-x-3 p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#dialogs" }, [
    _createVNode(_Transition, {
      "enter-active-class": "transition duration-100 ease-out",
      "enter-from-class": "transform scale-95 opacity-0",
      "enter-to-class": "transform scale-100 opacity-100",
      "leave-active-class": "transition duration-75 ease-out",
      "leave-from-class": "transform scale-100 opacity-100",
      "leave-to-class": "transform scale-95 opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickOutside && _ctx.clickOutside(...args))),
                class: "bg-black bg-opacity-50 fixed inset-0 z-40"
              }),
              _createElementVNode("div", {
                class: _normalizeClass(["bg-white rounded shadow-xl z-40", `${_ctx.size}`])
              }, [
                _createElementVNode("div", null, [
                  _renderSlot(_ctx.$slots, "default", {}, () => [
                    _createTextVNode("Modal Content")
                  ])
                ]),
                (_ctx.showErrors)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("ul", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (msg) => {
                          return (_openBlock(), _createElementBlock("li", { key: msg }, _toDisplayString(msg), 1))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showActions)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "actions")
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}