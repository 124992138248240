<template>
  <HTransitionRoot as="template" :show="isOpen">
    <HDialog as="div" class="fixed inset-0 overflow-hidden z-40" @close="isOpen = false">
      <div class="absolute inset-0 overflow-hidden">
        <HDialogOverlay class="absolute inset-0 bg-black opacity-50" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex pl-10">
          <HTransitionChild as="template" enter="transform transition ease-in-out duration-300" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-200" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-3xl overflow-y-auto bg-white">
              <n-info v-if="!module" type="warning">No module provided.</n-info>
              <div v-else class="px-6 bg-brownish-gray-100 flex flex-row items-center gap-x-10 justify-between">
                <div class="flex flex-row items-center py-4">
                  <n-icon :name="iconName" scale="2.5" class="mr-4 text-brownish-gray-500"></n-icon>
                  <div>
                    <h2 class="text-lg font-bold">{{ module.friendlyName }}</h2>
                    <p class="text-xs text-brownish-gray-500 max-w-lg">{{ module.description }}</p>
                  </div>
                </div>
                <div class="flex flex-row items-start">
                  <div class="flex flex-row items-center whitespace-nowrap py-4">
                    <div class="mr-2 w-10 h-10 border rounded-full border-brownish-gray-300 bg-white flex items-center justify-center">
                      <n-icon v-if="module.orgName === 'nullstone'" name="nullstone" scale="1.8" class="text-blue-500"></n-icon>
                      <span v-else class="text-2xl font-bold capitalize">{{ module.orgName[0] }}</span>
                    </div>
                    <div>
                      <h2 class="font-bold">{{ module.orgName }}</h2>
                      <span class="text-brownish-gray-500">{{ module.name }}</span>
                    </div>
                  </div>
                  <button type="button" class="ml-6 -mr-4 text-brownish-gray-500 hover:text-brownish-gray-600 focus:outline-none p-2" @click="isOpen = false">
                    <span class="sr-only">Close panel</span>
                    <n-icon name="fas/times"></n-icon>
                  </button>
                </div>
              </div>

              <div class="p-8">
                <n-info v-if="content.length === 0" class="border-brownish-gray-300 p-6">
                  A <span class="font-bold">README.md</span> file was not included for this module.
                </n-info>
                <n-markdown v-else :content="content"></n-markdown>
              </div>
            </div>
          </HTransitionChild>
        </div>
      </div>
    </HDialog>
  </HTransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Dialog as HDialog, DialogOverlay as HDialogOverlay, TransitionChild as HTransitionChild, TransitionRoot as HTransitionRoot } from '@headlessui/vue'
import modelWrapper from '@/lib/modelWrapper'
import Module from '@/models/module'
import getModuleIconName from '@/lib/get_module_icon_name'
import getCategoryIconName from '@/lib/get_category_icon_name'
import NMarkdown from '@/components/base/Markdown.vue'

const props = defineProps({
  module: {
    type: Object as () => Module
  },
  content: {
    type: String,
    required: true
  },
  open: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['update:open'])

const isOpen = modelWrapper<boolean>(props, emit, 'open')
const iconName = computed(() => {
  if (!props.module) return ''
  if (props.module.category === 'app') return getCategoryIconName(props.module.subcategory)
  return getModuleIconName(props.module)
})
</script>
