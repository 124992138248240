import { VuexModule, Module as VCModule, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import ModuleVersionService from '@/services/module_version_service'
import Module from '@/models/module'

@VCModule
class SlideoverModule extends VuexModule {
  module: Module | undefined = undefined
  content = ''
  show = false

  @Action
  async showModuleReadme({ module, moduleVersion }: { module: Module, moduleVersion: string }) {
    const mv = await ModuleVersionService.get(module.orgName, module.name, moduleVersion)
    this.setModule(module)
    this.setContent(mv.readme)
    this.setShow(true)
  }

  @Mutation
  setModule(module: Module | undefined) {
    this.module = module
  }

  @Mutation
  setContent(content: string) {
    this.content = content
  }

  @Mutation
  setShow(show: boolean) {
    this.show = show
  }
}

export default new SlideoverModule({ store, name: 'slideover' })
