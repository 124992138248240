export interface GcpProviderConfig {
  providerName: string
  region: string
  zone: string
}

export function blankGcpProviderConfig(): GcpProviderConfig {
  const reg = GcpRegions()[0]
  return {
    providerName: '',
    region: reg.code,
    zone: `${reg.code}${reg.zones[0]}`
  }
}

export interface GcpRegion {
  code: string;
  name: string;
  zones: string[];
}

export function isGcpProviderConfigValid(pc: GcpProviderConfig): boolean {
  return !!pc.providerName && !!pc.region && !!pc.zone
}

export function GcpRegions(): GcpRegion[] {
  return [
    {
      code: 'us-central1',
      name: 'Council Bluffs, Iowa',
      zones: ['a', 'b', 'c', 'f']
    },
    {
      code: 'us-east1',
      name: 'Moncks Corner, South Carolina',
      zones: ['a', 'b', 'c', 'd']
    },
    {
      code: 'us-east4',
      name: 'Ashburn, Virginia',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'us-west1',
      name: 'The Dalles, Oregon',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'us-west2',
      name: 'Los Angeles, California',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'us-west3',
      name: 'Salt Lake City, Utah',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'us-west4',
      name: 'Las Vegas, Nevada',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'northamerica-northeast1',
      name: 'Montréal, Québec',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'southamerica-east1',
      name: 'Osasco, São Paulo, Brazil',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-central2',
      name: 'Warsaw, Poland',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-north1',
      name: 'Hamina, Finland',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-west1',
      name: 'St. Ghislain, Belgium',
      zones: ['b', 'c', 'd']
    },
    {
      code: 'europe-west2',
      name: 'London, England',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-west3',
      name: 'Frankfurt, Germany',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-west4',
      name: 'Eemshaven, Netherlands',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'europe-west6',
      name: 'Zurich, Switzerland',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-east1',
      name: 'Changhua County, Taiwan',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-east2',
      name: 'Hong Kong',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-northeast1',
      name: 'Tokyo, Japan',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-northeast2',
      name: 'Osaka, Japan',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-northeast3',
      name: 'Seoul, South Korea',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-south1',
      name: 'Mumbai, India',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-south2',
      name: 'Delhi, India',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-southeast1',
      name: 'Jurong West, Singapore',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'asia-southeast2',
      name: 'Jakarta, Indonesia',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'australia-southeast1',
      name: 'Sydney, Australia',
      zones: ['a', 'b', 'c']
    },
    {
      code: 'australia-southeast2',
      name: 'Melbourne, Australia',
      zones: ['a', 'b', 'c']
    }
  ]
}
