<template>
  <div id="sidebar" class="bg-gray-800 w-56">
    <div class="h-14 flex items-center justify-center">
      <a href="/" class="flex items-center title mr-4">
        <n-icon name="nullstone/header" width="36" height="36" class="mr-2"></n-icon>
        <span class="text-white text-[16px] font-semibold">Nullstone</span>
      </a>
    </div>
    <n-the-org-selector></n-the-org-selector>
    <div class="flex flex-col overflow-y-auto dark-scrolling">
      <div class="mt-4 text-xs px-5 py-1 text-brownish-gray-500">stacks</div>
      <n-menu-item v-for="stack in stacks" :key="stack.id" :menu-item="stackMenuItem(stack)" class="mx-2"></n-menu-item>
      <n-menu-item v-if="authorizeOrgArchitect(orgName)" class="mx-2" :menu-item="newStackMenuItem"></n-menu-item>

      <div class="mt-8 text-xs px-5 py-1 text-brownish-gray-500">global configuration</div>
      <n-menu-item class="mx-2" v-for="item in infraMenuItems" :menu-item="item" :key="item.path"></n-menu-item>
      <n-menu-item class="mx-2 mb-2" :menu-item="settingsMenuItem"></n-menu-item>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import NTheOrgSelector from '@/components/TheOrgSelector.vue'
import NMenuItem from '@/components/MenuItem.vue'
import orgStore from '@/store/orgs'
import stackStore from '@/store/stacks'
import { authorizeOrgArchitect } from '@/lib/authorization/org_authorization'
import Stack from '@/models/stack'
import { getCurrentUser } from '@/lib/use_current_user'

const route = useRoute()
const orgName = computed(() => {
  return route.params.orgName as string || orgStore.currentOrg
})
const stacks = computed(() => {
  const user = getCurrentUser()
  const stackIds = user.stackIds
  return stackStore.query(orgName.value).filter(stack => stackIds.includes(stack.id))
})
const root = computed(() => {
  return `/orgs/${orgName.value}`
})
const stackMenuItem = (stack: Stack) => {
  return {
    path: `${root.value}/stacks/${stack.id}/overview`,
    title: stack.name,
    icon: 'fas/layer-group',
    highlighted: route.path.startsWith(`${root.value}/stacks/${stack.id}/`)
  }
}
const newStackMenuItem = computed(() => {
  return {
    path: `${root.value}/stacks/new`,
    title: 'Add New Stack',
    icon: 'fas/plus',
    highlighted: route.path === `${root.value}/stacks/new`
  }
})
const infraMenuItems = computed(() => {
  return [
    {
      path: `${root.value}/cloud_accounts`,
      title: 'Cloud Accounts',
      icon: 'fas/cloud',
      highlighted: route.path.startsWith(`${root.value}/cloud_accounts`)
    },
    {
      path: `${root.value}/integrations`,
      title: 'Integrations',
      icon: 'fas/plug',
      highlighted: route.path.startsWith(`${root.value}/integrations`)
    }
  ]
})
const settingsMenuItem = computed(() => {
  return {
    path: `${root.value}/settings`,
    title: 'Org Settings',
    icon: 'fas/cog',
    highlighted: route.path.startsWith(`${root.value}/settings`)
  }
})
</script>
