import { Dictionary } from '@/models/dictionary'
import Module from '@/models/module'
import { capitalize } from '@/lib/string'
import moduleStore from '@/store/modules'
import { uiSortedCategories } from '@/models/module_category'
import { matchContract, parseModuleContractName } from '@/models/module_contract_name'
import blockStore from '@/store/blocks'

export enum BlockType {
  Block = 'Block',
  Application = 'Application',
  Datastore = 'Datastore',
  Domain = 'Domain',
  Subdomain = 'Subdomain',
  Ingress = 'Ingress',
  ClusterNamespace = 'ClusterNamespace',
  Cluster = 'Cluster',
  Network = 'Network'
}

export interface ConnectionTarget {
  stackId: number,
  blockId?: number,
  blockName?: string,
  envId?: number
}

export default interface Block {
  id: number
  reference?: string
  type: string
  orgName: string
  name: string
  stackId: number
  moduleSource: string
  moduleSourceVersion: string
  connections: Dictionary<ConnectionTarget>
  createdAt?: Date
  updatedAt?: Date
  dnsName?: string
  registrar?: string
  repo?: string
  framework?: string
  isShared: boolean
}

export function blankBlock(orgName: string, stackId: number): Block {
  return { id: 0, type: 'Block', orgName, name: '', stackId, moduleSource: '', moduleSourceVersion: '', isShared: false, connections: {}, framework: 'other' }
}

export function uniqueBlockName(name: string, existingBlocks: Block[]): string {
  let i = 0
  let newName = `${name}${i}`
  while (existingBlocks.find(block => block.name === newName)) {
    newName = `${name}${i}`
    i++
  }
  return newName
}

export function blockType(module: Module): string {
  if (module.category.startsWith('app')) {
    return 'Application'
  }
  if (module.category.startsWith('capability')) {
    return 'Block'
  }
  if (module.category.startsWith('cluster-namespace')) {
    return 'ClusterNamespace'
  }
  return capitalize(module.category)
}

export function sortBlocksByCategoryThenName(orgName: string, blocks: Block[]): Block[] {
  return blocks.sort((a, b) => {
    const aCategory = moduleStore.find(orgName, a.moduleSource)?.category || 'block'
    const bCategory = moduleStore.find(orgName, b.moduleSource)?.category || 'block'
    const categoryDiff = uiSortedCategories.indexOf(aCategory) - uiSortedCategories.indexOf(bCategory)
    if (categoryDiff !== 0) return categoryDiff
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
}

export function sortCategory(c1: string, c2: string): number {
  let index1 = uiSortedCategories.indexOf(c1)
  if (index1 === -1) index1 = 99
  let index2 = uiSortedCategories.indexOf(c2)
  if (index2 === -1) index2 = 99
  return index1 - index2
}

export const blockMatchesContract = (block: Block, contract: string): boolean => {
  const parsed = parseModuleContractName(contract)
  const module = moduleStore.find(block.orgName, block.moduleSource)
  return matchContract(parsed, module)
}

export const findBlocksByContract = (orgName: string, stackId: number, contract: string): Block[] => {
  return blockStore.query(orgName, stackId).filter(b => blockMatchesContract(b, contract))
}

export const resolveBlockFromConnection = (orgName: string, target: ConnectionTarget): Block | undefined => {
  if (target.blockId) {
    return blockStore.find(orgName, target.stackId, target.blockId)
  }
  if (target.blockName) {
    return blockStore.query(orgName, target.stackId).find(b => b.name === target.blockName)
  }
  return undefined
}

export const findDefaultBlock = (blocks: Block[], defaultModules: string[]): Block | undefined => {
  // first, look for blocks that matches the contract AND use a default module
  const matching = blocks.filter(b => defaultModules.includes(b.moduleSource))

  // if we find at least one, return the first one
  if (matching.length > 0) {
    return matching[0]
  }

  // if we don't find any, look for blocks that just match the contract
  // if blocks.length is 0, this will return undefined
  return blocks[0]
}

export const newBlock = (orgName: string, stackId: number, baseName: string, type: string, moduleSource: string, moduleSourceVersion = 'latest'): Block => {
  const name = uniqueBlockName(baseName, blockStore.query(orgName, stackId))
  return {
    type,
    orgName,
    stackId,
    name,
    moduleSource,
    moduleSourceVersion,
    connections: {}
  } as Block
}

export const blockCategory = (block: Block): string => {
  if (block.type === 'Application') {
    return 'app'
  }
  if (block.type === 'ClusterNamespace') {
    return 'cluster-namespace'
  }
  return block.type.toLowerCase()
}
