<template>
  <div id="container" class="relative overflow-x-hidden px-4">
    <div id="bar" class="absolute inset-0 opacity-75" :class="bgColor"></div>
    <div id="line" class="inset-0 absolute z-10" :class="[bgColor, active ? 'dec' : '']"></div>
    <div class="absolute inset-0 z-20">
      <slot/>
    </div>
    <!-- The slot is intentionally duplicated here to give the progress bar the correct height -->
    <slot/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressBarComponent',
  props: {
    bgColor: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped>
.dec {
  animation: decrease 1.5s 0s infinite;
}

@keyframes decrease {
  from { left: -80%; width: 80%; }
  to { left: 110%; width: 10%;}
}
</style>
