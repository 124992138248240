import posthog from 'posthog-js'
import User from '@/models/user'

class Analytics {
  key: string | undefined
  user: User | undefined

  init(key: string) {
    this.key = key
    posthog.init(key)
  }

  identify(user: User) {
    // if no key has been configured, we don't need to do anything here
    if (!this.key) { return }
    // if we already have a user and it is the same one - just exit
    if (this.user && this.user.id === user.id) { return }

    this.user = user
    posthog.identify(
      user.name,
      {},
      { userId: user.id, userEmail: user.email }
    )
  }
}

const analytics = new Analytics()

export default analytics
