import { VuexModule, Module as VCModule, Mutation, Action } from 'vuex-class-modules'
import store from '@/store'
import Environment, { EnvironmentType } from '@/models/environment'
import { Dictionary } from '@/models/dictionary'
import EnvironmentService from '@/services/environment_service'

@VCModule
class EnvsModule extends VuexModule {
  envs = {} as Dictionary<Environment[]>

  get query() {
    return (orgName: string, stackId: number, types = [EnvironmentType.Pipeline, EnvironmentType.Global]): Environment[] => {
      const envs = this.envs[this.key(orgName, stackId)] || []
      // filter the results based on the list of types
      // check to see if the env type is in the list of types
      return envs.filter(e => types.some(t => t === e.type))
        .sort((a, b) => (a.pipelineOrder || 99) > (b.pipelineOrder || 99) ? 1 : -1)
    }
  }

  get find() {
    return (orgName: string, stackId: number, envId: number): Environment | undefined => {
      return (this.envs[this.key(orgName, stackId)] || []).find(e => e.id === envId)
    }
  }

  get globalEnv() {
    return (orgName: string, stackId: number): Environment | undefined => {
      const envs = this.envs[this.key(orgName, stackId)] || []
      return envs.find(e => e.type === 'GlobalEnv')
    }
  }

  get sharedEnv() {
    return (orgName: string, stackId: number): Environment | undefined => {
      const envs = this.envs[this.key(orgName, stackId)] || []
      return envs.find(e => e.type === 'PreviewsSharedEnv')
    }
  }

  private key(orgName: string, stackId: number): string {
    return `${orgName}:${stackId}`
  }

  @Action
  async update({ orgName, stackId, env }: { orgName: string, stackId: number, env: Environment }) {
    await EnvironmentService.update(orgName, stackId, env.id, env)
    this.setEnv({ orgName, stackId, env })
  }

  @Action
  async createEnv({ orgName, stackId, env }: { orgName: string, stackId: number, env: Environment }) {
    const newEnv = await EnvironmentService.create(orgName, stackId, env)
    this.addEnv({ orgName, stackId, env: newEnv })
  }

  @Action
  async loadEnvs({ orgName, stackId }: { orgName: string, stackId: number }) {
    const results = await EnvironmentService.query(orgName, stackId)
    this.setEnvs({ orgName, stackId, envs: results })
  }

  @Action
  async removeEnv({ orgName, stackId, envId }: { orgName: string, stackId: number, envId: number }) {
    await EnvironmentService.delete(orgName, stackId, envId)
    this.deleteEnv({ orgName, stackId, envId })
  }

  @Mutation
  setEnv({ orgName, stackId, env }: { orgName: string, stackId: number, env: Environment }) {
    const existing = this.envs[this.key(orgName, stackId)].find(e => e.id === env.id)
    if (existing) {
      Object.assign(existing, env)
    }
  }

  @Mutation
  addEnv({ orgName, stackId, env }: { orgName: string, stackId: number, env: Environment }) {
    this.envs[this.key(orgName, stackId)].push(env)
  }

  @Mutation
  setEnvs({ orgName, stackId, envs }: { orgName: string, stackId: number, envs: Environment[] }) {
    this.envs[this.key(orgName, stackId)] = envs
  }

  @Mutation
  deleteEnv({ orgName, stackId, envId }: { orgName: string, stackId: number, envId: number }) {
    const index = this.envs[this.key(orgName, stackId)].findIndex(e => e.id === envId)
    if (index >= 0) {
      this.envs[this.key(orgName, stackId)].splice(index, 1)
    }
  }
}

export default new EnvsModule({ store, name: 'envs' })
