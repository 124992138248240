import { Ref, watchEffect } from 'vue'
import { getStackWorkspaceStatusesUrl, WebsocketMessage } from '@/services/ws'
import { useWebSocket } from '@vueuse/core'
import workspaceStatusStore from '@/store/workspace_statuses'

const useLiveStackWorkspaceStatuses = (orgName: Ref<string>, stackId: Ref<number>) => {
  let closeWs: WebSocket['close']
  const close = () => {
    if (closeWs) closeWs()
  }

  const start = async (orgName: string | undefined, stackId: number | undefined) => {
    if (!orgName || !stackId) return
    const wsUrl = await getStackWorkspaceStatusesUrl(orgName, stackId);
    ({ close: closeWs } = useWebSocket(wsUrl, {
      heartbeat: {
        message: 'ping',
        interval: 10000
      },
      onMessage: receiveStatuses,
      autoReconnect: true
    }))
  }

  const receiveStatuses = (ws: WebSocket, message: MessageEvent) => {
    const parsed = JSON.parse(message.data) as WebsocketMessage
    if (parsed.context === 'hydrate') {
      const statuses = JSON.parse(parsed.content) || []
      workspaceStatusStore.setStatuses(statuses)
    } else {
      const ws = JSON.parse(parsed.content)
      workspaceStatusStore.upsertStatus(ws)
    }
  }

  watchEffect(async () => {
    close()
    await start(orgName.value, stackId.value)
  })
}

export default useLiveStackWorkspaceStatuses
