<template>
  <teleport to="#dialogs">
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <div v-if="isOpen" class="absolute inset-0 flex justify-center items-center">
        <div @click="clickOutside" class="bg-black bg-opacity-50 fixed inset-0 z-40"></div>
        <div class="bg-white rounded shadow-xl z-40" :class="`${size}`">
          <div>
            <slot>Modal Content</slot>
          </div>
          <div v-if="showErrors" class="border-t border-b border-red-700 bg-red-100 text-red-700 text-xs p-6">
            <ul class="text-xs text-red-700 list-disc ml-4">
              <li :key="msg" v-for="msg in errors">{{ msg }}</li>
            </ul>
          </div>
          <div v-if="showActions" class="rounded-b border-t border-brownish-gray-300 bg-brownish-gray-100 flex flex-row items-center justify-end space-x-3 p-3">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted, onUnmounted } from 'vue'

export default defineComponent({
  name: 'ModalComponent',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object as () => string[]
    },
    size: {
      type: String,
      default: 'min-w-64 max-w-6xl'
    }
  },
  setup(props, { slots, emit }) {
    const isOpen = ref(props.open)
    const showActions = computed(() => {
      return 'actions' in slots
    })

    const close = () => {
      isOpen.value = false
      emit('update:open', isOpen.value)
    }

    const clickOutside = () => {
      if (!props.modal) {
        close()
      }
    }

    watch(() => props.open as boolean, newValue => {
      isOpen.value = newValue
    })

    const keypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close()
      }
    }

    const showErrors = computed(() => {
      return props.errors && props.errors.length > 0
    })

    onMounted(() => {
      window.addEventListener('keydown', keypress)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', keypress)
    })

    return { isOpen, showActions, close, clickOutside, showErrors }
  }
})
</script>
