const getCategoryIconName = (category: string): string => {
  switch (category) {
  case 'container':
    return 'container'
  case 'serverless':
    return 'lambda'
  case 'static-site':
    return 'browser'
  case 'server':
    return 'server'
  default:
    // TODO: add a default icon
    return ''
  }
}

export default getCategoryIconName
