<template>
  <div class="rounded-full h-2 w-2" :class="`${styles}`"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  appStatus: {
    type: String as () => 'not-launched' | 'launched' | 'live',
    required: true
  }
})

const styles = computed(() => {
  switch (props.appStatus) {
  case 'live':
    return 'pulsing-dot'
  case 'launched':
    return 'bg-blue-700'
  default:
    return 'bg-brownish-gray-400'
  }
})
</script>
