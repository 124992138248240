<template>
  <div class="rounded-lg shadow-lg flex flex-row p-4 space-x-3 border" :class="cardStyles">
    <div class="flex-shrink-0 w-5 h-5">
      <n-icon scale="1.4" :name="icon" :class="titleColor"></n-icon>
    </div>
    <div class="flex-1 flex flex-col justify-center">
      <h2 class="font-semibold" :class="titleColor">{{ notification.message }}</h2>
      <div v-if="notification.details.length > 0" class="mt-2 text-xs">
        <p v-for="(detail, i) in notification.details" :key="i" class="break-all">{{ detail }}</p>
      </div>
    </div>
    <div class="shrink-0">
      <button class="opacity-50 hover:opacity-75" @click.prevent="removeNotification">
        <n-icon name="fas/times" scale="1.2"></n-icon>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Notification, NotificationTypes } from '@/store/notifications'
import { computed, onMounted } from 'vue'

const props = defineProps<{
  notification: Notification
  duration?: number
}>()

const emit = defineEmits(['removeNotification'])

const removeNotification = () => {
  emit('removeNotification', props.notification)
}

const cardStyles = computed(() => {
  switch (props.notification.type) {
  case NotificationTypes.error:
    return 'bg-red-100 border-red-700/25'
  case NotificationTypes.info:
    return 'bg-blue-100 border-blue-700/25'
  case NotificationTypes.warning:
    return 'bg-yellow-100 border-yellow-700/25'
  case NotificationTypes.success:
    return 'bg-green-100 border-green-700/25'
  default:
    throw new Error('Invalid notification type')
  }
})

const icon = computed(() => {
  switch (props.notification.type) {
  case NotificationTypes.error:
    return 'fas/times-circle'
  case NotificationTypes.info:
    return 'fas/info-circle'
  case NotificationTypes.warning:
    return 'fas/exclamation-triangle'
  case NotificationTypes.success:
    return 'fas/check-circle'
  default:
    throw new Error('Invalid notification type')
  }
})

const titleColor = computed(() => {
  switch (props.notification.type) {
  case NotificationTypes.error:
    return 'text-red-700'
  case NotificationTypes.info:
    return 'text-blue-700'
  case NotificationTypes.warning:
    return 'text-yellow-700'
  case NotificationTypes.success:
    return 'text-green-700'
  default:
    throw new Error('Invalid notification type')
  }
})

onMounted(() => {
  if (props.notification.type === NotificationTypes.error) return
  setTimeout(() => removeNotification(), props.duration || 3000)
})
</script>
