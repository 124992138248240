<template>
  <button class="rounded focus:outline-none select-none whitespace-nowrap"
    :class="[enabledStyles, sizeStyles, typeStyles]">
    <slot/>
    <n-icon v-if="working" name="fas/sync-alt" :spin="true" class="ml-4"></n-icon>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, useAttrs } from 'vue'

export enum ButtonSize {
  NORMAL = 'normal',
  SMALL = 'small'
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger'
}

export default defineComponent({
  props: {
    size: {
      type: String as () => ButtonSize,
      default: ButtonSize.NORMAL
    },
    type: {
      type: String as () => ButtonType,
      default: ButtonType.PRIMARY
    },
    working: {
      type: Boolean,
      default: false
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const attrs = useAttrs()

    const sizeStyles = computed(() => {
      switch (props.size) {
      case ButtonSize.SMALL:
        return 'px-2 py-1 text-xs'
      case ButtonSize.NORMAL:
      default:
        return 'px-4 py-2'
      }
    })

    const typeStyles = computed(() => {
      if (props.darkMode) {
        switch (props.type) {
        case ButtonType.DANGER:
          return 'bg-red-700 text-white'
        case ButtonType.SECONDARY:
          return 'border border-brownish-gray-400 bg-brownish-gray-400 text-brownish-gray-800'
        case ButtonType.TERTIARY:
          return 'text-blue-300'
        case ButtonType.PRIMARY:
        default:
          return 'border border-blue-700 bg-blue-700 text-white'
        }
      } else {
        switch (props.type) {
        case ButtonType.DANGER:
          return 'border border-red-700 bg-red-700 text-white'
        case ButtonType.SECONDARY:
          return 'border border-brownish-gray-300 bg-white text-brownish-gray-800'
        case ButtonType.TERTIARY:
          return 'text-blue-700'
        case ButtonType.PRIMARY:
        default:
          return 'border border-blue-700 bg-blue-700 text-white'
        }
      }
    })

    const enabledStyles = computed(() => {
      if (attrs.disabled || props.working) {
        return 'cursor-default opacity-50'
      }
      const result = 'cursor-pointer opacity-100 focus:ring'
      if (props.darkMode) {
        switch (props.type) {
        case ButtonType.DANGER:
          return result + ' hover:bg-red-600'
        case ButtonType.SECONDARY:
          return result + ' hover:bg-brownish-gray-300'
        case ButtonType.TERTIARY:
          return result + ' hover:bg-brownish-gray-200'
        case ButtonType.PRIMARY:
        default:
          return result + ' hover:bg-blue-600'
        }
      } else {
        switch (props.type) {
        case ButtonType.DANGER:
          return result + ' hover:bg-red-600'
        case ButtonType.SECONDARY:
          return result + ' hover:bg-brownish-gray-100'
        case ButtonType.TERTIARY:
          return result + ' hover:bg-brownish-gray-200'
        case ButtonType.PRIMARY:
        default:
          return result + ' hover:bg-blue-600'
        }
      }
    })

    return { sizeStyles, typeStyles, enabledStyles }
  }
})
</script>
