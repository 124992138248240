import { VuexModule, Module, Mutation } from 'vuex-class-modules'
import store from '@/store'
import Workspace from '@/models/workspace'

@Module
class WorkspacesModule extends VuexModule {
  workspaces = [] as Workspace[]

  get query() {
    return (): Workspace[] => {
      return this.workspaces
    }
  }

  get find() {
    return (blockId: number, envId: number): Workspace | undefined => {
      return this.workspaces.find(ws => {
        return ws.blockId === blockId && ws.envId === envId
      })
    }
  }

  get findByUid() {
    return (workspaceUid: string): Workspace | undefined => {
      return this.workspaces.find(ws => {
        return ws.uid === workspaceUid
      })
    }
  }

  private upsert(workspace: Workspace) {
    const existing = this.workspaces.find(ws => ws.uid === workspace.uid)
    if (existing) {
      Object.assign(existing, workspace)
    } else {
      this.workspaces.push(workspace)
    }
  }

  @Mutation
  upsertWorkspace(workspace: Workspace) {
    this.upsert(workspace)
  }

  @Mutation
  setWorkspaces(workspaces: Workspace[]) {
    this.workspaces = workspaces
  }
}

export default new WorkspacesModule({ store, name: 'workspaces' })
