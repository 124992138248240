export interface AwsProviderConfig {
  providerName: string
  region: string
}

export function blankAwsProviderConfig(): AwsProviderConfig {
  return {
    providerName: '',
    region: AwsRegions()[0].code
  }
}

export interface AwsRegion {
  code: string;
  name: string;
  optInRequired: boolean;
}

export function isAwsProviderConfigValid(pc: AwsProviderConfig): boolean {
  return !!pc.providerName && !!pc.region
}

export function AwsRegions(): AwsRegion[] {
  return [
    {
      code: 'us-east-1',
      name: 'US East (N. Virginia)',
      optInRequired: false
    },
    {
      code: 'us-east-2',
      name: 'US East (Ohio)',
      optInRequired: false
    },
    {
      code: 'us-west-1',
      name: 'US West (N. California)',
      optInRequired: false
    },
    {
      code: 'us-west-2',
      name: 'US West (Oregon)',
      optInRequired: false
    },
    {
      code: 'af-south-1',
      name: 'Africa (Cape Town)',
      optInRequired: true
    },
    {
      code: 'ap-east-1',
      name: 'Asia Pacific (Hong Kong)',
      optInRequired: true
    },
    {
      code: 'ap-south-1',
      name: 'Asia Pacific (Mumbai)',
      optInRequired: false
    },
    {
      code: 'ap-northeast-1',
      name: 'Asia Pacific (Tokyo)',
      optInRequired: false
    },
    {
      code: 'ap-northeast-2',
      name: 'Asia Pacific (Seoul)',
      optInRequired: false
    },
    {
      code: 'ap-northeast-3',
      name: 'Asia Pacific (Osaka-Local)',
      optInRequired: false
    },
    {
      code: 'ap-southeast-1',
      name: 'Asia Pacific (Singapore)',
      optInRequired: false
    },
    {
      code: 'ap-southeast-2',
      name: 'Asia Pacific (Sydney)',
      optInRequired: false
    },
    {
      code: 'ca-central-1',
      name: 'Canada (Central)',
      optInRequired: false
    },
    {
      code: 'eu-central-1',
      name: 'Europe (Frankfurt)',
      optInRequired: false
    },
    {
      code: 'eu-west-1',
      name: 'Europe (Ireland)',
      optInRequired: false
    },
    {
      code: 'eu-west-2',
      name: 'Europe (London)',
      optInRequired: false
    },
    {
      code: 'eu-west-3',
      name: 'Europe (Paris)',
      optInRequired: false
    },
    {
      code: 'eu-south-1',
      name: 'Europe (Milan)',
      optInRequired: true
    },
    {
      code: 'eu-north-1',
      name: 'Europe (Stockholm)',
      optInRequired: false
    },
    {
      code: 'me-south-1',
      name: 'Middle East (Bahrain)',
      optInRequired: true
    },
    {
      code: 'sa-east-1',
      name: 'South America (São Paulo)',
      optInRequired: false
    }
  ]
}
