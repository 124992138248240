import { getCurrentUser } from '@/lib/use_current_user'

export function authorizeOrgMember(orgName: string): boolean {
  const currentUser = getCurrentUser()
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  if (!roleInOrg) { return false }
  return roleInOrg === 'member' || roleInOrg === 'architect' || roleInOrg === 'admin'
}

export function authorizeOrgArchitect(orgName: string): boolean {
  const currentUser = getCurrentUser()
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  if (!roleInOrg) { return false }
  return roleInOrg === 'architect' || roleInOrg === 'admin'
}

export function authorizeOrgAdmin(orgName: string): boolean {
  const currentUser = getCurrentUser()
  // TODO: once everyone has been moved over to the new token we can remove this guard clause
  if (!currentUser.roles) { return true }
  const roleInOrg = currentUser.roles[orgName]
  if (!roleInOrg) { return false }
  return roleInOrg === 'admin'
}
