import { createAuth } from '@/services/api'

export default class SessionService {
  public static async delete(): Promise<null> {
    const auth = await createAuth()
    await auth.delete<null>('/sessions')
    return null
  }

  public static async create(email: string, password: string): Promise<null> {
    const auth = await createAuth()
    await auth.post<null>('/sessions?method=email', { email, password })
    return null
  }
}
