import { VuexModule, Module, Mutation } from 'vuex-class-modules'
import store from '@/store'

export const NotificationTypes = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
}

type ObjectValues<T> = T[keyof T]

export type NotificationTypes = ObjectValues<typeof NotificationTypes>

export interface Notification {
  key: symbol
  type: NotificationTypes
  message: string
  details: string[]
}

@Module
class NotificationsModule extends VuexModule {
  notifications: Notification[] = []

  @Mutation
  clear() {
    this.notifications = []
  }

  @Mutation
  add({ type, message, details = [] }: { type: NotificationTypes, message: string, details: string[] }) {
    this.notifications.unshift({ key: Symbol('key'), type, message, details })
  }

  @Mutation
  remove(notification: Notification) {
    const index = this.notifications.indexOf(notification)
    if (index > -1) {
      this.notifications.splice(index, 1)
    }
  }
}

export default new NotificationsModule({ store, name: 'notifications' })
