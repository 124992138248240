import { RouteLocationNormalized, Router } from 'vue-router'
import Block from '@/models/block'

const routePrefix = (block: Block) => {
  switch (block.type) {
  case 'Application':
    return 'app'
  case 'Datastore':
    return 'datastore'
  case 'Domain':
    return 'domain'
  default:
    return 'block'
  }
}

const calcNewRouteName = (route: RouteLocationNormalized, block: Block) => {
  const prefix = routePrefix(block)

  // check to see if we are on a run or deploy
  // if it is, we need to go to the activity page instead
  const isActivityPage = route.matched.find(r => {
    return (r.name as string).endsWith('env_workflow')
  })
  if (isActivityPage) {
    return `${prefix}_env_activity`
  }

  const routeNameParts = (route.name as string).split('_')
  return [prefix, ...routeNameParts.slice(1)].join('_')
}

// take the current route.name, and replace the prefix (app/datastore/domain/block) with the new prefix
// determine if this route exists
// if it does, use it
// if it doesn't, use the overview route
const calcRouteName = (route: RouteLocationNormalized, router: Router, block: Block) => {
  const newRouteName = calcNewRouteName(route, block)
  if (!router.hasRoute(newRouteName)) {
    const prefix = routePrefix(block)
    return `${prefix}_env_overview`
  }
  return newRouteName
}

const routeToBlock = (route: RouteLocationNormalized, router: Router, block: Block) => {
  const params = { ...route.params }
  const routeName = calcRouteName(route, router, block)
  params.blockId = block.id.toString()

  router.push({ name: routeName, params })
}

export default routeToBlock
