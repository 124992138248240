import Block from '@/models/block'
import { modulePlatform } from '@/models/module'
import { isAutogenSubdomain } from '@/models/subdomain'

export default function getBlockIconName(block: Block): string {
  switch (block?.type) {
  case 'Application':
    return block.framework && block.framework !== 'other' ? block.framework : 'fas/code'
  case 'Datastore':
    return modulePlatform(block.orgName, block.moduleSource) || 'fas/database'
  case 'Subdomain':
    return isAutogenSubdomain(block.moduleSource) ? 'autogen-subdomain' : 'custom-subdomain'
  case 'Domain':
    return 'custom-subdomain'
  case 'Ingress':
    return modulePlatform(block.orgName, block.moduleSource) || 'fas/cube'
  default:
    return 'fas/cube'
  }
}
