<template>
  <div class="shrink-0 rounded-full overflow-hidden flex flex-row items-center justify-center bg-brownish-gray-200" :class="`${boxSize}`">
    <n-icon v-if="sourceUrl && sourceUrl.startsWith('external/github')" name="fab/github" :scale="`${iconScale}`" class="m-1 text-brownish-gray-400"></n-icon>
    <img v-else-if="sourceUrl" alt="avatar" :src="sourceUrl" class="rounded-full overflow-hidden"/>
    <n-icon v-else name="fas/user" :scale="`${iconScale}`" class="m-1 text-brownish-gray-400"></n-icon>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  sourceUrl: {
    type: String
  },
  size: {
    type: String,
    default: 'medium'
  }
})

const iconScale = computed(() => {
  switch (props.size) {
  case 'small':
    return 1
  case 'medium':
    return 1.8
  default: // large
    return 4.2
  }
})

const boxSize = computed(() => {
  switch (props.size) {
  case 'small':
    return 'h-4 w-4'
  case 'medium':
    return 'h-9 w-9'
  default: // large
    return 'h-20 w-20'
  }
})
</script>
