import { computed, ComputedRef } from 'vue'
import stackStore from '@/store/stacks'
import { MaybeRef, resolveRef } from '@vueuse/core'

const useGlobalStack = (orgName: MaybeRef<string>, stackId: MaybeRef<number>): {
  isGlobal: ComputedRef<boolean>
} => {
  const isGlobal = computed(() => {
    return stackStore.find(resolveRef(orgName).value, resolveRef(stackId).value)?.name === 'global'
  })

  return { isGlobal }
}

export default useGlobalStack
