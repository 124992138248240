<template>
  <div class="relative border rounded flex flex-row bg-white border-brownish-gray-300"
       :class="$attrs.disabled ? 'opacity-50': ''">
    <label v-if="label" class="absolute top-0 left-0 pointer-events-none transform origin-top-left duration-300 ease-in-out text-brownish-gray-500 translate-x-2"
           :style="labelUp ? '--tw-scale-x: .75; --tw-scale-y: .75; --tw-translate-y: .2rem; --tw-translate-x: .55rem;': '--tw-translate-y: .55rem;'"
    >{{ label }} <span v-if="required">*</span></label>
    <textarea v-model.trim="value" @focus="onFocused" @blur="onBlurred" :disabled="$attrs.disabled"
              :rows="lines"
              class="w-full rounded appearance-none focus:outline-none focus:ring px-2"
              :class="label ? 'pt-4': 'py-2'"
              :style="label ? 'padding-bottom: .15rem;': ''"></textarea>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import modelWrapper from '@/lib/modelWrapper'
import useFocus from '@/components/base/use_focus'

export default defineComponent({
  name: 'TextAreaComponent',
  props: {
    modelValue: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    lines: {
      type: Number,
      default: 2
    }
  },
  setup(props, { emit }) {
    const value = modelWrapper<string | number>(props, emit)
    const hasValue = computed(() => {
      return value.value.toString().length > 0
    })
    const { focused, onFocused, onBlurred } = useFocus()

    const labelUp = computed(() => {
      return hasValue.value || focused.value
    })

    return { focused, value, hasValue, onFocused, onBlurred, labelUp }
  }
})
</script>
