import Block from '@/models/block'

export default interface Subdomain extends Block {
  dnsName: string
}

// TODO: these should both be dynamic based on the provider type
export const AWS_AUTOGEN_MODULE = 'nullstone/autogen-aws-subdomain'
export const GCP_AUTOGEN_MODULE = 'nullstone/autogen-gcp-subdomain'
// TODO: how would a user change this default in case they want to use their own module?
export const AWS_DEFAULT_SUBDOMAIN_MODULE = 'nullstone/aws-subdomain'
export const GCP_DEFAULT_SUBDOMAIN_MODULE = 'nullstone/gcp-subdomain'

export function isDefaultSubdomain(moduleSource: string): boolean {
  return [AWS_DEFAULT_SUBDOMAIN_MODULE, GCP_DEFAULT_SUBDOMAIN_MODULE].includes(moduleSource)
}

export function isAutogenSubdomain(moduleSource: string): boolean {
  return [AWS_AUTOGEN_MODULE, GCP_AUTOGEN_MODULE].includes(moduleSource)
}
