<template>
  <div class="w-full h-full">
    <h3>{{ title }} {{ unit }}</h3>
    <div class="w-full h-full">
      <Line :data="data" :options="options" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Line } from 'vue-chartjs'
import { ChartOptions } from 'chart.js'
import { MetricDataset } from '@/models/metrics'
import { computed } from 'vue'
import { durationMetricsToChartData } from '@/components/base/charts/duration_metrics'

const props = defineProps<{
  rawData: MetricDataset
  timeframe: { start: Date, end: Date }
}>()

const data = computed(() => durationMetricsToChartData(props.rawData))
const title = computed(() => props.rawData?.name || '')
const unit = computed(() => `(${props.rawData?.unit})` || '')

const options = computed((): ChartOptions<'line'> => {
  return {
    plugins: {
      crosshair: {
        line: {
          color: 'rgb(43 108 176)',
          width: 1
        },
        sync: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        snap: {
          enabled: false
        },
        filler: {

        }
      },
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    interaction: {
      mode: 'index'
    },
    hover: {
      intersect: false
    },
    scales: {
      x: {
        type: 'time',
        min: props.timeframe.start,
        max: props.timeframe.end,
        grid: {
          lineWidth: 0,
          drawTicks: false
        }
      },
      y: {
        type: 'linear',
        grid: {
          drawTicks: false
        },
        beginAtZero: true
      }
    }
  }
})

</script>
