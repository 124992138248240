import {
  Chart,
  Interaction,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  Filler
} from 'chart.js'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>window).Chart = Chart as never

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>Interaction.modes).interpolate = Interpolate
Chart.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, TimeScale, CrosshairPlugin, Filler)
Chart.defaults.font.family = 'Chinese Quotes, Inter var, Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji'
