import { createApi } from '@/services/api'
import ModuleVersion from '@/models/module_version'
import { rcompare } from 'semver'
import Module from '@/models/module'

export default class ModuleVersionService {
  public static async query(module: Module): Promise<ModuleVersion[]> {
    const api = await createApi()
    const res = await api.get<ModuleVersion[]>(`orgs/${module.orgName}/modules/${module.name}/versions`)
    return res.data.sort((a, b) => rcompare(a.version, b.version))
  }

  public static async get(orgName: string, moduleName: string, version: string): Promise<ModuleVersion> {
    const api = await createApi()
    if (version === 'latest') {
      const versions = await this.query({ orgName, name: moduleName } as Module)
      return versions[0]
    }
    const res = await api.get<ModuleVersion>(`orgs/${orgName}/modules/${moduleName}/versions/${version}`)
    return res.data
  }
}
