import { ProviderCredentials } from '@/models/providers/provider_credentials'
import AwsCredentials, {
  awsAuthTypeNames,
  AwsAuthTypes,
  generateAwsAssumeRoleCredentials
} from '@/models/providers/aws_credentials'
import GcpCredentials, { blankGcpServiceAccountCredentials } from '@/models/providers/gcp_credentials'

export enum ProviderTypes {
  Aws = 'aws',
  Gcp = 'gcp',
  Azure = 'azure'
}

export function providerTypeIdLabel(providerType: string): string {
  switch (providerType) {
  case 'aws':
    return 'AWS Account Id'
  case 'gcp':
    return 'GCP Project Id'
  default:
    return 'Provider Id'
  }
}

export default interface Provider {
  id?: number;
  name: string;
  providerType: string;
  providerId: string;
  credentials: ProviderCredentials;
}

export function blankProviderCredentials(providerType: string): ProviderCredentials {
  switch (providerType) {
  case ProviderTypes.Aws:
    return generateAwsAssumeRoleCredentials()
  case ProviderTypes.Gcp:
    return blankGcpServiceAccountCredentials()
  default:
    throw new Error(`Invalid provider type: ${providerType}`)
  }
}

export function providerAuthType(provider: Provider | undefined): string {
  switch (provider?.providerType) {
  case ProviderTypes.Aws:
    return awsAuthTypeNames[(provider.credentials as AwsCredentials).authType] || ''
  case ProviderTypes.Gcp:
    return 'Service Account'
  default:
    return ''
  }
}

export function blankProvider(providerType: ProviderTypes = ProviderTypes.Aws): Provider {
  return {
    name: '',
    providerType,
    providerId: '',
    credentials: blankProviderCredentials(providerType)
  }
}

export const providerTypes = [ProviderTypes.Aws, ProviderTypes.Gcp] //, ProviderTypes.Azure]
export const supportedProviderTypes = [ProviderTypes.Aws, ProviderTypes.Gcp]

function isAwsProviderValid(provider: Provider) {
  const credentials = provider.credentials as AwsCredentials
  if (!credentials.authType) { return false }
  if (credentials.authType === AwsAuthTypes.accessKeys) {
    if (!credentials.accessKeyId || !credentials.secretAccessKey) { return false }
  }
  if (credentials.authType === AwsAuthTypes.assumeRole) {
    if (!credentials.assumeRoleExternalId || !credentials.assumeRoleName) { return false }
  }
  return true
}

function isGcpProviderValid(provider: Provider) {
  const credentials = provider.credentials as GcpCredentials
  if (!credentials.authType) { return false }
  if (!credentials.serviceAccount?.client_id) { return false }
  return true
}

export function isProviderValid(provider: Provider) {
  if (!provider.providerType) { return false }
  if (!provider.name) { return false }
  if (!provider.providerId) { return false }
  switch (provider.providerType) {
  case ProviderTypes.Aws:
    return isAwsProviderValid(provider)
  case ProviderTypes.Gcp:
    return isGcpProviderValid(provider)
  default:
    return false
  }
}
