<template>
  <div class="flex flex-col items-center px-4 py-6 rounded cursor-pointer border-brownish-gray-300"
     :class="[ isActive ? 'bg-white': '', selectedFile ? 'border bg-white': 'border-2 border-dashed' ]"
     @drop="drop($event)" @dragover="dragover($event)" @dragenter="dragenter($event)" @dragleave="dragleave($event)"
     @mouseover="mouseover()" @mouseleave="mouseleave()" @click="openInput()">
    <svg class="w-8 h-8 text-blue-700" :class="selectedFile ? 'text-blue-700': 'text-brownish-gray-400'" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
    </svg>
    <span v-if="selectedFile" class="mt-2 text-center">{{ selectedFile?.name }}</span>
    <span v-else class="mt-2 text-center">{{ message }}</span>
    <input ref="uploader" type="file" class="hidden" @change="changeFile" />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Upload', // eslint-disable-line vue/multi-word-component-names
  emits: ['file-selected'],
  props: {
    message: {
      type: String,
      default: 'Drop a file here or click here to select a file'
    }
  },
  setup(props, { emit }) {
    const uploader = ref(document.createElement('input'))
    const selectedFile = ref(null as File|null)

    const selectFile = (file: File) => {
      selectedFile.value = file
      emit('file-selected', selectedFile.value)
    }

    // This counter ensures that "isDroppingFile" is accurate with nested html elements
    // drag events trigger on all ancestor html elements
    // Without this, dragging over the text or graphic would force to unhighlight
    let counter = 0
    const isHoveringFile = ref(false)
    const isDroppingFile = ref(false)
    const isActive = computed(() => {
      return isHoveringFile.value || isDroppingFile.value
    })
    const mouseover = () => {
      isHoveringFile.value = true
    }
    const mouseleave = () => {
      isHoveringFile.value = false
    }

    const drop = (e: DragEvent) => {
      e.preventDefault()
      isDroppingFile.value = false
      selectFile((e?.dataTransfer?.files || [])[0])
    }

    // This is how we check to see if the user is dropping a file
    const isEventDraggingFile = (e: DragEvent) => {
      return (e?.dataTransfer?.types || []).indexOf('Files') > -1
    }

    const dragover = (e: DragEvent) => {
      if (isEventDraggingFile(e)) {
        e.preventDefault()
      }
    }

    const dragenter = (e: DragEvent) => {
      e.preventDefault()
      if (isEventDraggingFile(e)) {
        counter++
        isDroppingFile.value = true
      }
    }

    const dragleave = () => {
      counter--
      counter < 1 && (isDroppingFile.value = false)
    }

    const changeFile = (e: Event) => {
      selectFile(((e.target as HTMLInputElement).files || [])[0])
    }

    const openInput = () => {
      if (uploader.value) {
        uploader.value.click()
      }
    }

    return { uploader, isActive, mouseover, mouseleave, drop, dragover, dragenter, dragleave, openInput, changeFile, selectedFile }
  }
})
</script>
