export interface MetricDataPoint {
    t: Date
    v: number
}

export interface MetricSeries {
    id: string
    minValue: number
    maxValue: number
    datapoints: MetricDataPoint[]
}

export function blankSeries(id: string): MetricSeries {
  return {
    id,
    minValue: Number.MAX_VALUE,
    maxValue: 0,
    datapoints: []
  }
}

export enum MetricDatasetType {
    Usage = 'usage',
    UsagePercent = 'usage-percent',
    Invocations = 'invocations',
    Duration = 'duration'
}

export interface MetricDataset {
    name: string
    type: MetricDatasetType
    unit: string
    series: Record<string, MetricSeries>
}
export default interface MetricsData {
    metrics: Record<string, MetricDataset>
}
