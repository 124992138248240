import { blankProviderConfig, ProviderConfig } from '@/models/providers/provider_config'

export enum EnvironmentType {
  Global = 'GlobalEnv',
  Pipeline = 'PipelineEnv',
  Preview = 'PreviewEnv',
  PreviewsShared = 'PreviewsSharedEnv'
}

export default interface Environment {
  id: number
  type: string
  stackId: number
  name: string
  providerConfig: ProviderConfig
  pipelineOrder?: number
  createdAt: Date
  isProd: boolean
}

export function blankEnv(stackId: number): Environment {
  const current = new Date()
  return {
    id: 0,
    type: EnvironmentType.Pipeline,
    stackId,
    name: '',
    providerConfig: blankProviderConfig(),
    createdAt: current,
    isProd: false
  }
}

export function order(a: Environment, b: Environment): number {
  const aOrder = a.pipelineOrder || 99
  const bOrder = b.pipelineOrder || 99
  return aOrder - bOrder
}

export function uniquePreviewEnvName(prId: number, title: string): string {
  let name = title.toLowerCase().replace(/[^a-z0-9-]/g, '-')
  while (name.indexOf('--') > -1) {
    name = name.replace('--', '-')
  }
  const availableLength = 32 - 1 - prId.toString().length
  name = name.slice(0, availableLength)
  return `${name}-${prId}`
}
