import { computed, ComputedRef, Ref, WritableComputedRef } from 'vue'

const usePagination = (total: Ref<number>, page: WritableComputedRef<number>, pageSize: Ref<number>): {
  pageCount: ComputedRef<number>,
  next: () => void,
  prev: () => void,
  isFirstPage: ComputedRef<boolean>,
  isLastPage: ComputedRef<boolean>
} => {
  const pageCount = computed(() => {
    return Math.ceil(total.value / pageSize.value)
  })

  const next = () => {
    page.value = Math.min(page.value + 1, pageCount.value)
  }
  const prev = () => {
    page.value = Math.max(page.value - 1, 1)
  }

  const isFirstPage = computed(() => {
    return page.value === 1
  })
  const isLastPage = computed(() => {
    return page.value === pageCount.value
  })

  return { pageCount, next, prev, isFirstPage, isLastPage }
}

export default usePagination
