import { Dictionary } from '@/models/dictionary'

export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

export default class User {
  id: string
  name: string
  email: string
  picture: string
  roles: Dictionary<string> = {}
  nullstoneAdmin: boolean

  constructor(id: string, name: string, email: string, picture: string, roles: Dictionary<string>) {
    this.id = id
    this.name = name
    this.email = email
    this.picture = picture
    this.roles = roles
    this.nullstoneAdmin = false
  }

  get isAuthenticated(): boolean {
    return !!this.id
  }

  get orgNames(): string[] {
    const roleKeys = Object.keys(this.roles)
    return roleKeys.reduce((array, roleKey) => {
      if (!roleKey.startsWith('stack:')) {
        array.push(roleKey)
      }
      return array
    }, [] as string[])
  }

  get stackIds(): number[] {
    const roleKeys = Object.keys(this.roles)
    return roleKeys.reduce((array, roleKey) => {
      if (roleKey.startsWith('stack:')) {
        const id = parseInt(roleKey.split(':')[1])
        array.push(id)
      }
      return array
    }, [] as number[])
  }
}

// we need to return a new object each time to ensure that this is immutable
export const blankUser = (): User => new User('', '', '', '', {})
