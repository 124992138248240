<template>
  <div class="prose prose-sm max-w-2xl" v-html="markdownContent"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { marked } from 'marked'

const props = defineProps<{
  content: string
}>()

const markdownContent = computed(() => {
  return marked(props.content)
})
</script>
