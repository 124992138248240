<template>
  <!--
    TODO: this uses the HeadlessUI Menu directly
    this should be refactored to use a Dropdown that has a lot of this defaulted
  -->
  <h-menu as="div" class="relative">
    <h-menu-button class="border rounded border-brownish-gray-300 hover:bg-brownish-gray-100 w-5 h-5 flex flex-row items-center justify-center">
      <n-icon name="chevron-down" scale="1.2" class="text-brownish-gray-600"></n-icon>
    </h-menu-button>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <h-menu-items class="absolute z-50 mt-2 bg-white border rounded border-brownish-gray-300 focus:outline-none shadow-md">
        <div class="min-w-80">
          <div class="px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">stacks</div>
          <div v-for="stack in stacks" :key="stack.id" class="border-t border-brownish-gray-300 select-none">
            <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="stack.id === stackId">
              <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                      :class="[active || stack.id === stackId ? 'bg-brownish-gray-100' : '', stack.id === stackId ? 'cursor-default' : 'cursor-pointer']"
                      @click="goToStack(stack.id)">
                <n-icon name="fas/layer-group" class="text-brownish-gray-400"></n-icon>
                <span :class="stack.id === stackId ? 'font-semibold' : ''">{{ stack.name }}</span>
                <n-icon :name="stack.providerType"></n-icon>
              </button>
            </h-menu-item>
          </div>
        </div>
      </h-menu-items>
    </transition>
  </h-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Menu as HMenu, MenuButton as HMenuButton, MenuItems as HMenuItems, MenuItem as HMenuItem } from '@headlessui/vue'
import stackStore from '@/store/stacks'
import { useRoute, useRouter } from 'vue-router'
import routeToStack from '@/lib/route_to_stack'
import { getCurrentUser } from '@/lib/use_current_user'

const props = defineProps<{
  orgName: string
  stackId: number
}>()

const stacks = computed(() => {
  const user = getCurrentUser()
  const stackIds = user.stackIds
  return stackStore.query(props.orgName).filter(stack => stackIds.includes(stack.id))
})

const route = useRoute()
const router = useRouter()
const goToStack = (newStackId: number) => {
  routeToStack(route, router, newStackId)
}
</script>
